import { InMemoryCache } from '@apollo/client';
import {} from './generated.ts';


const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				// products: {
				// 	keyArgs: ['search', 'categoryId', 'subCategoryId'],
				// 	merge(
				// 		existing: ProductListFieldsFragment | undefined,
				// 		incoming: ProductListFieldsFragment,
				// 		opts
				// 	) {
				// 		const { limit, offset } = opts.variables as {
				// 			limit: number;
				// 			offset: number;
				// 		};
				// 		const mergedList = existing?.list.slice(0) || [];
				// 		const end = offset + Math.min(limit, incoming.list.length);
				// 		for (let i = offset; i < end; ++i) {
				// 			mergedList[i] = incoming.list[i - offset];
				// 		}
				// 		const final: ProductListFieldsFragment = {
				// 			...incoming,
				// 			list: mergedList,
				// 			hasNextPage:
				// 				existing?.hasNextPage == false ? false : incoming.hasNextPage,
				// 		};
				// 		return final;
				// 	},
				// },
				// merchantOrders: {
				// 	keyArgs: false,
				// 	merge(
				// 		existing: OrderListFieldsFragment | undefined,
				// 		incoming: OrderListFieldsFragment,
				// 		opts
				// 	) {
				// 		const { limit, offset } = opts.variables as {
				// 			limit: number;
				// 			offset: number;
				// 		};
				// 		const mergedList = existing?.list.slice(0) || [];
				// 		const end = offset + Math.min(limit, incoming.list.length);
				// 		for (let i = offset; i < end; ++i) {
				// 			mergedList[i] = incoming.list[i - offset];
				// 		}
				// 		const final: OrderListFieldsFragment = {
				// 			...incoming,
				// 			list: mergedList,
				// 			hasNextPage:
				// 				existing?.hasNextPage == false ? false : incoming.hasNextPage,
				// 		};
				// 		return final;
				// 	},
				// },
			},
		},
	},
	possibleTypes: {
		User: ['FoodMerchantUser', 'ShopMerchantUser', 'StudentUser'],
	},
});

export default cache;
