import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import AmlHashForm from "aml-hash-form";
import moment from "moment";
// includes
import { common } from "../../helpers/common";
import { config } from "../../helpers/config";
import { api } from "../../helpers/api";
import { GoogleMap, MarkerF, LoadScript } from "@react-google-maps/api";
import { RiDeleteBin6Line } from "react-icons/ri";

import { useDropzone } from "react-dropzone";
import { CropIt } from "../cropIt";
import { geocode, RequestType } from "react-geocode";

const stateOption = [
  { value: "england", label: "England" },
  { value: "northireland", label: "Northern Ireland" },
  { value: "scotland", label: "Scotland" },
];
const cityOption = [
  { value: "southampton", label: "Southampton" },
  { value: "bristol", label: "Bristol" },
  { value: "liverpool", label: "Liverpool" },
];
const orderOption = [
  { value: ["dine_in"], label: "dine_in" },
  { value: ["take_away"], label: "take_away" },
  { value: ["dine_in", "take_away"], label: "dine_in & take_away" },
];

export default function Step4(props) {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedCountry, setCountry] = useState();
  const [selectedState, setState] = useState();
  const [selectedCity, setCity] = useState();
  const [logoPath, setLogoPath] = useState(null);
  const [bannerPath, setBannerPath] = useState(null);
  const [logoEdit, setLogoEdit] = useState(false);
  const [bannerEdit, setBannerEdit] = useState(false);
  const [documentEdit, setDocumentEdit] = useState(false);
  const [isUploadLogo, setIsUploadLogo] = useState(false);
  const [isUploadBanner, setIsUploadBanner] = useState(false);
  const [isUploadDocuments, setIsUploadDocuments] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedBanner, setCroppedBanner] = useState(null);
  const [selectedFilesEdit, setSelectedFilesEdit] = useState([]);
  const defaultOpenTime = moment().set({
    hour: 8,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const defaultCloseTime = moment().set({
    hour: 22,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const [timeData, setTimeData] = useState([
    {
      weekDay: 0,
      isOpen: true,
      openingTime: defaultOpenTime.format("HH:mm"),
      closingTime: defaultCloseTime.format("HH:mm"),
    },
    {
      weekDay: 1,
      isOpen: true,
      openingTime: defaultOpenTime.format("HH:mm"),
      closingTime: defaultCloseTime.format("HH:mm"),
    },
    {
      weekDay: 2,
      isOpen: true,
      openingTime: defaultOpenTime.format("HH:mm"),
      closingTime: defaultCloseTime.format("HH:mm"),
    },
    {
      weekDay: 3,
      isOpen: true,
      openingTime: defaultOpenTime.format("HH:mm"),
      closingTime: defaultCloseTime.format("HH:mm"),
    },
    {
      weekDay: 4,
      isOpen: true,
      openingTime: defaultOpenTime.format("HH:mm"),
      closingTime: defaultCloseTime.format("HH:mm"),
    },
    {
      weekDay: 5,
      isOpen: true,
      openingTime: defaultOpenTime.format("HH:mm"),
      closingTime: defaultCloseTime.format("HH:mm"),
    },
    {
      weekDay: 6,
      isOpen: true,
      openingTime: defaultOpenTime.format("HH:mm"),
      closingTime: defaultCloseTime.format("HH:mm"),
    },
  ]);

  const [order, setOrder] = useState();
  const [error, setError] = useState({
    logo: "",
    banner: "",
    document: "",
    location: "",
    orderTypes: "",
  });
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({
    lat: 51.50072749664255,
    lng: -0.12462539999999998,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documentsPath, setDocumentsPath] = useState([]);
  const [center, setCenter] = useState({
    lat: 51.50072749664255,
    lng: -0.12462539999999998,
  });
  const [geocoder, setGeocoder] = useState(null);
  // const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [profileData, setProfileData] = useState(
    common.localGet("profileData")
      ? JSON.parse(common.localGet("profileData"))
      : {}
  );
  const [days, setDays] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);
  // form
  const formFields = {
    brand_name: {
      validate: ["req#Brand Name is required"],
    },
    description: {
      validate: ["req#Description is required"],
    },
    address1: {
      validate: ["req#Address is required"],
    },
    address2: {
      validate: ["req#Area is required"],
    },
    // open_time: {
    //   validate: ["req#Open Time is required"],
    // },
    // close_time: { validate: ["req#Close Time is required"] },
    phone: { validate: ["req#Phone Number is required", "type:+-number"] },
    country: {
      // validate: ["objValReq#Please select the Country"],
      // obj: "value",
    },
    state: {
      //  validate: ["req#State is required"]
    },
    city: { validate: ["req#City is required"] },
    websiteUrl: {
      // validate: ["req#Website url is required"]
    },
    postalCode: { validate: ["req#postal code  is required"] },
    businessRegistrationNumber: {
      validate: [
        "req#Business Registration Number is required!",
        "min:8#Business Registration Number should be minimum 8 Digits",
        "max:15#Business Registration Number should be maximum 15 Digits",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

  useEffect(() => {
    getCountry();
    getCurrentUser();
  }, []);

  // useEffect(() => {
  //   // setGeocoder(new window.google.maps.Geocoder());
  //   if (isOpen == false) {

  //   }
  // }, [isOpen]);

  useEffect(() => {
    if (props.edit) {
      // console.log("NBDkahlkdh", props?.user?.profile?.operatingDays);
      const updatedTimeData = props?.user?.profile?.operatingDays?.map(
        (day, i) => ({
          weekDay: i,
          isOpen: day.isOpen,
          openingTime: day.openingTime,
          closingTime: day.closingTime,
        })
      );

      setTimeData(updatedTimeData);

      let odType = {
        value: props?.user?.profile?.orderTypes,
        label: props?.user?.profile?.orderTypes,
      };
      setOrder(odType);
      setCenter({
        lat: 51.50072749664255,
        lng: -0.12462539999999998,
      });
      setLocation({
        lat: props.user?.profile?.location?.lat,
        lng: props.user?.profile?.location?.lng,
      });
      setSelectedFiles(props.user?.profile?.businessDocuments);

      setMultiValue({
        brand_name: props.user?.profile?.name,
        description: props.user?.profile?.description,
        address1: props.user?.profile?.addressLine1,
        address2: props.user?.profile?.addressLine2,
        // open_time: props?.openTime,
        // close_time: props?.closeTime,
        phone: props.user?.profile?.phone,
        country: countryOption?.find(
          (option) => option.value == props.user?.profile?.country
        ),
        state: props.user?.profile?.state,
        city: props.user?.profile?.city,
        websiteUrl: props.user?.profile?.websiteUrl,
        postalCode: props.user?.profile?.postalCode,
        businessRegistrationNumber:
          props.user?.profile?.businessRegistrationNumber,
      });
    }
  }, []);

  const getCountry = () => {
    let countryQuery = `query GetCountries {
      countries {
        id
        name
        iso2
        iso3
        numCode
        phoneCode
        emoji
      }
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "none",
      body: {
        query: countryQuery,
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      setCountry(data?.data?.countries);
    });
  };
  const countryOption = selectedCountry?.map((option) => ({
    label: option.name,
    value: option.iso2,
  }));
  const ukOption = countryOption?.find((option) => option.value == "GB");

  /**Image Crop  */
  let channelImageCroper = React.useRef(null);
  let channerBannerCroper = React.useRef(null);
  const handleLogo = (event) => {
    setLogoEdit(true);
    const file = event.target.files[0];
    if (file) {
      // setSelectedLogo(URL.createObjectURL(file));

      let cropSize = {};
      cropSize = { width: 256, height: 256 };

      channelImageCroper.onFileChange(event, cropSize);

      setSelectedLogo(file);
    } else {
      setSelectedLogo(null);
      setCroppedImage(null);
    }
  };

  const cropComplete = (data, type) => {
    if (data.status == "success") {
      // let image = data.croppedImage.split(",");
      setCroppedImage(data.url);
      fileInputRef.current.value = "";
    } else {
      fileInputRef.current.value = "";
      setCroppedImage(null);
      setSelectedLogo(null);
    }
  };
  const cropBannerComplete = (data, type) => {
    if (data.status == "success") {
      // let image = data.croppedImage.split(",");
      setCroppedBanner(data.url);
      filebrandRef.current.value = "";
    } else {
      filebrandRef.current.value = "";
      setCroppedBanner(null);
      setSelectedBanner(null);
    }
  };
  const handleBanner = (event) => {
    const file = event.target.files[0];
    setBannerEdit(true);
    if (file) {
      // setSelectedBanner(URL.createObjectURL(file));
      let cropSize = {};
      cropSize = { width: 600, height: 300 };

      channerBannerCroper.onFileChange(event, cropSize);
      setSelectedBanner(file);
    } else {
      setSelectedBanner(null);

      setCroppedBanner(null);
    }
  };

  const handleRemoveFile = (e, index) => {
    e.preventDefault();
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const uploadLogo = async () => {
    setIsUploadLogo(true);
    const formData = new FormData();
    formData.append("file", selectedLogo);
    let data = {
      url: "upload",
      method: "POST",
      auth: "token",
      body: formData,
      cType: 6,
    };
    return new Promise((resolve, reject) => {
      api.call(data, (res) => {
        let response = res.data;
        if (res.status == 200) {
          let logo = response?.data?.key;
          setLogoPath(logo);
          setIsUploadLogo(false);
          resolve(response?.data?.key);
          return logo;
        } else {
          common.notify("E", "Error In Uploading documents ,please try again");
          reject("Error in uploading logo");
        }
      });
    });
  };
  const uploadBanner = () => {
    setIsUploadBanner(true);
    const formData = new FormData();
    formData.append("file", selectedBanner);
    let data = {
      url: "upload",
      method: "POST",
      auth: "token",
      body: formData,
      cType: 6,
    };
    return new Promise((resolve, reject) => {
      api.call(data, (res) => {
        let response = res.data;
        if (res.status == 200) {
          let banner = response?.data?.key;
          setBannerPath(banner);
          setIsUploadBanner(false);
          resolve(response?.data?.key);
          return banner;
        } else {
          common.notify("E", "Error In Uploading documents ,please try again");
          reject("Error in uploading logo");
        }
      });
    });
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const handleOrder = (data) => {
    setOrder(data);
  };
  const getCurrentUser = async () => {
    // api call
    // let userQuery = `query GetCurrentUser {
    //   currentUser {
    //     __typename
    //     id
    //     name
    //     email
    //     avatar
    //     socialAvatar
    //     userType
    //     ... on FoodMerchantUser {
    //       profile {
    //         __typename
    //         id
    //          name
    //          image
    //          bannerImage
    //         description
    //         websiteUrl
    //         orderTypes
    //         country
    //         addressLine1
    //         addressLine2
    //         city
    //         state
    //         postalCode
    //         location {
    //           lat
    //           lng
    //         }
    //         businessRegistrationNumber
    //         businessDocuments
    //         vatId
    //         orderTypes
    //         operatingDays {
    //           id
    //           weekDay
    //           weekDayName
    //           isOpen
    //           openingTime
    //           closingTime
    //         }
    //         stripeAccountStatus
    //       }
    //     }
    //   }
    // }`;
    let userQuery = `query CurrentUser {
      currentUser {
          id
          name
          email
          avatar
          socialAvatar
          userType
          profile {
              __typename
              ... on FoodMerchantPrivate {
                  id
                  name
                  description
                  image
                  bannerImage
                  phone
                  email
                  country
                  addressLine1
                  addressLine2
                  city
                  state
                  postalCode
                  businessRegistrationNumber
                  businessDocuments
                  vatId
                  orderTypes
                  websiteUrl
                  stripeAccountStatus
                  isActive
                  location {
                      lat
                      lng
                  }
                  operatingDays {
                      id
                      weekDay
                      weekDayName
                      isOpen
                      openingTime
                      closingTime
                  }
              }
          }
      }
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: userQuery,
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;

      setUser(data?.data?.currentUser);
    });
  };

  const handleContinue = async (e) => {
    try {
      let isValid = handleSubmit();
      if (isValid && props.edit && selectedFiles.length !== 0) {
        // if (logoEdit && !documentEdit && !bannerEdit) {
        //   const [logoPath] = await Promise.all([uploadLogo()]);
        //   profileSubmit(
        //     logoPath,
        //     props.user?.profile?.bannerImage,
        //     props.user?.profile?.businessDocuments
        //   );
        // }
        // if (!logoEdit && !documentEdit && bannerEdit) {
        //   const [bannerPath] = await Promise.all([uploadBanner()]);
        //   profileSubmit(
        //     props.user?.profile?.image,
        //     bannerPath,
        //     props.user?.profile?.businessDocuments
        //   );
        // }
        // if (documentEdit && !logoEdit && !bannerEdit) {
        //   const [documentsPath] = await Promise.all([
        //     common.uploadDocument(selectedFiles),
        //   ]);
        //   let documents = documentsPath;
        //   profileSubmit(
        //     props.user?.profile?.image,
        //     props.user?.profile?.bannerImage,
        //     documents
        //   );
        // }
        // if (logoEdit && bannerEdit && documentEdit) {
        //   const [logoPath, bannerPath, documentsPath] = await Promise.all([
        //     uploadLogo(),
        //     uploadBanner(),
        //     common.uploadDocument(selectedFiles),
        //   ]);
        //   let documents = documentsPath;
        //   profileSubmit(logoPath, bannerPath, documents);
        // }
        const existingDocuments = selectedFiles || [];
        const newFiles = selectedFiles.filter((file) => file instanceof File);
        const existingPaths = selectedFiles.filter(
          (file) => !(file instanceof File)
        );
        // Identify removed files
        const removedFiles = existingDocuments.filter(
          (file) =>
            !selectedFiles.find((selectedFile) => selectedFile.name === file)
        );
        const [logoPath, bannerPath, documentsPath] = await Promise.all([
          logoEdit ? uploadLogo() : Promise.resolve(null),
          bannerEdit ? uploadBanner() : Promise.resolve(null),

          newFiles.length > 0
            ? common.uploadDocument(newFiles)
            : Promise.resolve(null),
        ]);
        const updatedDocuments =
          newFiles.length > 0
            ? [...existingPaths, ...documentsPath]
            : existingDocuments;

        profileSubmit(
          logoPath || props.user?.profile?.image,
          bannerPath || props.user?.profile?.bannerImage,
          updatedDocuments
        );
      }
      if (
        isValid &&
        selectedBanner != null &&
        selectedLogo != null &&
        selectedFiles.length !== 0 &&
        !props.edit
      ) {
        const [logoPath, bannerPath, documentsPath] = await Promise.all([
          uploadLogo(),
          uploadBanner(),
          common.uploadDocument(selectedFiles),
        ]);
        let documents = documentsPath;

        profileSubmit(logoPath, bannerPath, documents);
      }
    } catch (error) {
      common.notify("E", "Error in File upload, please try again ");
    }
    if (!props.edit) {
      if (selectedBanner == null) {
        setError((prev) => ({ ...prev, banner: "Please select a Banner" }));
      }
      if (selectedLogo == null) {
        setError((prev) => ({ ...prev, logo: "Please select a Logo" }));
      }

      if (location == null) {
        setError((prev) => ({ ...prev, location: "Please select location" }));
      }
    }
    if (selectedFiles.length == 0) {
      setError((prev) => ({ ...prev, document: "Please select Documents" }));
    }
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const filebrandRef = useRef(null);
  const handleBrandClick = () => {
    if (filebrandRef.current) {
      filebrandRef.current.click();
    }
  };
  const onMapClick = (e) => {
    const coords = e.latLng;

    if (coords) {
      setLocation({
        lat: coords.lat(),
        lng: coords.lng(),
      });
    }
  };
  const mapContainerStyle = {
    height: "294px",
    width: "100%",
  };
  const handleDocuments = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);

    setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Update the files state with the accepted files

      setSelectedFiles([...selectedFiles, ...acceptedFiles]);
      setDocumentEdit(true);
    },
    [selectedFiles]
  );

  const profileSubmit = (logo, banner, document) => {
    setLoading(true);
    let doc = document?.length > 0 && document.map((item, i) => item);
    // const operatingDays = days.map((day, i) => ({
    //   weekDay: i,
    //   isOpen: timeData[i].isOpen,
    //   openingTime: timeData[i].openingTime,
    //   closingTime: timeData[i].closingTime,
    // }));

    // let filepath = selectedFiles.map((item, i) => item);

    // api call
    let foodQuery = props.edit
      ? `mutation UpdateFoodMerchantProfile($input: FoodMerchantProfileInput!) {
      updateFoodMerchantProfile(input: $input)
  }`
      : `mutation CreateFoodMerchantProfile($input: FoodMerchantProfileInput!) {
      createFoodMerchantProfile(input: $input)
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: foodQuery,
        variables: {
          input: {
            name: fields.brand_name,
            description: fields.description,
            // country: fields.country.value,
            country: ukOption.value,
            addressLine1: fields.address1,
            addressLine2: fields.address2,
            city: fields.city,
            state: fields.state,
            postalCode: fields.postalCode,
            phone: fields.phone,
            email: user.email,
            location: location,
            businessRegistrationNumber: fields.businessRegistrationNumber,

            businessDocuments: doc,
            image: logo,
            bannerImage: banner,
            orderTypes: props.edit ? order.value : profileData.shop,
            websiteUrl: fields.websiteUrl,
            operatingDays: updateOperatingDays(),
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;

      if (data?.errors) {
        data?.errors?.length > 0 &&
          data?.errors?.map((item) => {
            if (item?.extensions?.code === "UNEXPECTED_ERROR") {
              common.notify("E", "Something went wrong");
            }
          });
      } else {
        if (props.edit) {
          common.notify("S", "Profile updated successfully");
          props.close();
          props.update();
        } else {
          common.notify("S", "Profile saved successfully");
          createStripe();
        }
      }
      setLoading(false);
    });
  };

  const createStripe = () => {
    setLoading(true);
    // api call
    let stripeQuery = `query GetStripeAccountLink($input: StripeAccountLinkInput!) {
      stripeAccountLink(input: $input) {
        url
        created
        expiresAt
      }
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: stripeQuery,
        variables: {
          input: {
            type: "account_onboarding",
            collect: "eventually_due",
            refreshUrl: window.location.origin,
            returnUrl: window.location.origin + "/dashboard",
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let response = res?.data;
      if (response?.data?.stripeAccountLink) {
        window.location.replace(response?.data?.stripeAccountLink?.url);
      }
      setLoading(false);
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,

    noKeyboard: true,
    maxFiles: 15,
    accept: "image/*",
  });

  const getAddressCoordinates = async () => {
    const addressLine1 = fields.address1;
    const addressLine2 = fields.address2;
    const postalCode = fields.postalCode;

    const address = [addressLine1, addressLine2, postalCode].join(",");
    if (address) {
      geocode(RequestType.ADDRESS, address, {
        key: config.mapApiKey,
      })
        .then((response) => {
          if (response?.results?.length) {
            const loc = response?.results[0]?.geometry?.location;

            const coords = {
              lat: loc?.lat,
              lng: loc?.lng,
            };

            setCenter(coords);
            setLocation({
              lat: loc?.lat,
              lng: loc?.lng,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleOpenTime = (e, index) => {
    const value = e.target.value;
    setTimeData((prevTimeData) => ({
      ...prevTimeData,
      [index]: { ...prevTimeData[index], openingTime: value },
    }));
  };
  const handleCloseTime = (e, index) => {
    const value = e.target.value;
    setTimeData((prevTimeData) => ({
      ...prevTimeData,
      [index]: { ...prevTimeData[index], closingTime: value },
    }));
  };
  const handleIsOpen = (e, index) => {
    const { checked } = e.target;

    setTimeData((prevTimeData) => ({
      ...prevTimeData,
      [index]: {
        ...prevTimeData[index],
        isOpen: checked == true ? false : true,
      },
    }));
  };
  const updateOperatingDays = () => {
    const operatingDays = days.map((day, i) => ({
      weekDay: i,
      isOpen: timeData[i].isOpen,
      openingTime: timeData[i].openingTime,
      closingTime: timeData[i].closingTime,
    }));
    return operatingDays;
  };

  return (
    <div className="container">
      <div className="row ">
        <div
          className={
            props.edit == true ? "col-md-12 mx-auto" : "col-md-8 mx-auto"
          }
        >
          <div className="profile-steps-card">
            <div className="card-header">
              <span
                className="back-btn-wrap pc-cp"
                onClick={() => (props.edit ? props.close() : props.back())}
              >
                <img src={common.loadImg("back-arrow.svg")} alt="back-arrow" />
                <span>Back</span>
              </span>
              <div className="title-wrap">
                <h3 className="title">Brand Info</h3>
              </div>
            </div>
            <div className="card-body cuisines-category-wrap pt-1">
              <div className="row">
                <div className="col-md-3">
                  {/* <label className="form-label">Brand Logo</label>
                  <input
                    type="file"
                    className="form-control form-input"
                    accept="image/*"
                    onChange={(e) => handleLogo(e)}
                  />
                  <p className="error-txt">
                    {selectedLogo != null ? "" : error.logo}
                  </p> */}

                  <label className="form-label">Brand Logo</label>
                  <div className="logo-container" onClick={handleButtonClick}>
                    {props.edit && (
                      <img
                        // src={URL.createObjectURL(croppedImage)}
                        src={
                          croppedImage
                            ? croppedImage
                            : config.api.cloudImgPath +
                              props.user?.profile?.image
                        }
                        alt="Brand Logo"
                        className="uploaded-logo"
                      />
                    )}
                    {selectedLogo && croppedImage && (
                      <img
                        // src={URL.createObjectURL(croppedImage)}
                        src={croppedImage}
                        alt="Brand Logo"
                        className="uploaded-logo"
                      />
                    )}
                    {!selectedLogo && !croppedImage && !props.edit && (
                      <div className="click-load">Click to upload logo</div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="form-control form-input visually-hidden"
                    accept="image/*"
                    onChange={(e) => handleLogo(e)}
                  />
                  <CropIt
                    ref={(instance) => {
                      channelImageCroper = instance;
                    }}
                    onComplete={(data) => cropComplete(data, "profileImage")}
                    shape={"square"}
                  />
                  <p className="error-txt">
                    {selectedLogo != null ? "" : error.logo}
                  </p>
                </div>

                <div className="col-md-9">
                  <label className="form-label">Banner Image</label>
                  <div
                    className="brand-img-container"
                    onClick={handleBrandClick}
                  >
                    {props.edit && (
                      <img
                        // src={URL.createObjectURL(croppedImage)}
                        src={
                          croppedBanner
                            ? croppedBanner
                            : config.api.cloudImgPath +
                              props.user?.profile?.bannerImage
                        }
                        alt="Brand Logo"
                        className="uploaded-logo"
                      />
                    )}
                    {selectedBanner && croppedBanner && (
                      <img
                        // src={URL.createObjectURL(selectedBanner)}
                        src={croppedBanner}
                        alt="Brand Logo"
                        className="uploaded-logo"
                      />
                    )}
                    {!selectedBanner && !croppedBanner && !props.edit && (
                      <div className="click-load">Click to upload banner</div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={filebrandRef}
                    className="form-control form-input visually-hidden"
                    accept="image/*"
                    onChange={(e) => handleBanner(e)}
                  />
                  <CropIt
                    ref={(instance) => {
                      channerBannerCroper = instance;
                    }}
                    onComplete={(data) =>
                      cropBannerComplete(data, "BannerImage")
                    }
                    shape={"rectangle"}
                  />
                  <p className="error-txt">
                    {selectedBanner != null ? "" : error.banner}
                  </p>
                  {/* <label className="form-label">Banner Image</label>
                  <input
                    type="file"
                    className="form-control form-input"
                    accept="image/*"
                    onChange={(e) => handleBanner(e)}
                  />
                 
                  <p className="error-txt">
                    {selectedBanner != null ? "" : error.banner}
                  </p> */}
                </div>
                <div className="col-md-12">
                  <label className="form-label">Brand Name</label>
                  <input
                    type="text"
                    name="brand_name"
                    className="form-control form-input"
                    value={fields.brand_name}
                    onChange={handleChange}
                    placeholder="Enter the Name"
                  />
                  <p className="error-txt">{errors.brand_name}</p>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Description</label>
                  <input
                    type="text"
                    name="description"
                    className="form-control form-input"
                    value={fields.description}
                    onChange={handleChange}
                    placeholder="Describe your Brand"
                  />
                  <p className="error-txt">{errors.description}</p>
                </div>
                <div className="col-md-12">
                  <label className="form-label">
                    Business Registration Number
                  </label>
                  <input
                    type="text"
                    name="businessRegistrationNumber"
                    className="form-control form-input"
                    value={fields.businessRegistrationNumber}
                    onChange={handleChange}
                    placeholder="Enter Business Registration Number"
                  />
                  <p className="error-txt">
                    {errors.businessRegistrationNumber}
                  </p>
                </div>
                {days &&
                  days.length > 0 &&
                  days.map((day, i) => (
                    <>
                      <div className="col-md-5">
                        <label className="form-label" key={i}>
                          {day}-Open*
                        </label>
                        <input
                          id={"open_" + i}
                          type="time"
                          name="open_time"
                          className="form-control form-input"
                          value={timeData[i].openingTime}
                          onChange={(e) => handleOpenTime(e, i)}
                          disabled={timeData[i].isOpen ? false : true}
                        />
                        {/* <p className="error-txt">{errors.open_time}</p> */}
                      </div>
                      <div className="col-md-5">
                        <label className="form-label">{day}-Close*</label>
                        <input
                          id={"close_" + i}
                          // type="datetime-local"
                          type="time"
                          name="close_time"
                          className="form-control form-input"
                          value={timeData[i].closingTime}
                          onChange={(e) => handleCloseTime(e, i)}
                          disabled={timeData[i].isOpen ? false : true}
                        />
                        {/* <p className="error-txt">{errors.close_time}</p> */}
                      </div>
                      <div className="col-md-2 text-center">
                        <label className="form-label">Close</label>
                        <br></br>
                        <input
                          className="mt-3"
                          id={"closecheck_" + i}
                          // type="datetime-local"
                          type="checkbox"
                          value={timeData[i].isOpen}
                          checked={timeData[i].isOpen == true ? false : true}
                          onChange={(e) => handleIsOpen(e, i)}
                        />
                        {/* <p className="error-txt">{errors.close_time}</p> */}
                      </div>
                    </>
                  ))}
                <div className="col-md-12">
                  <label className="form-label">Phone</label>
                  <PhoneInput
                    name="phone"
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    // country={"uk"}
                    country={"gb"}
                    value={fields.phone}
                    onChange={(data) => setMultiValue({ phone: data })}
                  />
                  <p className="error-txt">{errors.phone}</p>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Address Line 1</label>
                  <input
                    type="text"
                    name="address1"
                    className="form-control form-input"
                    value={fields.address1}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Door,Street name "
                    onBlur={getAddressCoordinates}
                  />
                  <p className="error-txt">{errors.address1}</p>
                </div>
                <div className="col-md-12">
                  <label className="form-label">Address Line 2</label>
                  <input
                    type="text"
                    name="address2"
                    className="form-control form-input"
                    value={fields.address2}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={getAddressCoordinates}
                    placeholder="Area name"
                  />
                  <p className="error-txt">{errors.address2}</p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Country</label>
                  <Select
                    // value={selectedCountry}
                    name="country"
                    // value={fields.country}
                    value={ukOption}
                    options={countryOption}
                    className="react-select-container react-select-country"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        // borderColor: state.isFocused ? '#3073be' : '#3073be',
                        borderColor: "#3073be",
                        padding: "8px",
                        className: "select-control",
                      }),
                    }}
                    onChange={(data) => setMultiValue({ country: data })}
                    placeholder="Enter Country"
                  />
                  <p className="error-txt">{errors.country}</p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    className="form-control form-input"
                    value={fields.postalCode}
                    onChange={handleChange}
                    onBlur={getAddressCoordinates}
                    placeholder="Enter Postal COde"
                  />
                  <p className="error-txt">{errors.postalCode}</p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">State</label>
                  {/* <Select
                    name="state"
                    value={fields.state}
                    // value={selectedState}
                    options={stateOption}
                    className="react-select-container"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#3073be",
                        padding: "8px",
                        className: "select-control",
                      }),
                    }}
                    onChange={(data) => setMultiValue({ state: data })}
                  /> */}
                  <input
                    type="text"
                    name="state"
                    className="form-control form-input"
                    value={fields.state}
                    onChange={handleChange}
                    placeholder="Enter State"
                  />
                  <p className="error-txt">{errors.state}</p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">City</label>
                  {/* <Select
                    name="city"
                    value={fields.city}
                    // value={selectedCity}
                    options={cityOption}
                    className="react-select-container"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "#3073be",
                        padding: "8px",
                        className: "select-control",
                      }),
                    }}
                    onChange={(data) => setMultiValue({ city: data })}
                  /> */}
                  <input
                    type="text"
                    name="city"
                    className="form-control form-input"
                    value={fields.city}
                    onChange={handleChange}
                    placeholder="Enter City"
                  />
                  <p className="error-txt">{errors.city}</p>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Location</label>
                  <LoadScript
                    key={config.mapApiKey}
                    googleMapsApiKey={config.mapApiKey}
                  >
                    <GoogleMap
                      // mapContainerClassName="w-full h-96"
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={15}
                      onClick={onMapClick}
                    >
                      {/* position={location} */}
                      <MarkerF position={location}></MarkerF>
                    </GoogleMap>
                  </LoadScript>
                  <p className="error-txt">
                    {location != null ? "" : error.location}
                  </p>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Website URL</label>
                  <input
                    type="text"
                    className="form-control form-input"
                    name="websiteUrl"
                    value={fields.websiteUrl}
                    onChange={handleChange}
                    placeholder="Enter Website url"
                  />
                  <p className="error-txt">{errors.websiteUrl}</p>
                </div>
                {props.edit && (
                  <div className="col-md-12 mb-3">
                    {" "}
                    <label className="form-label">Order Type</label>
                    <Select
                      name="orderType"
                      options={orderOption}
                      value={order}
                      className="react-select-container react-select-country"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          // borderColor: state.isFocused ? '#3073be' : '#3073be',
                          borderColor: "#3073be",
                          padding: "8px",
                          className: "select-control",
                        }),
                      }}
                      onChange={(data) => handleOrder(data)}
                      placeholder="Enter Order"
                    />
                  </div>
                )}
                <div className="col-md-12 mb-3">
                  <label className="form-label">Restaurant Documents</label>
                  <div
                    {...getRootProps()}
                    className={`dropzone ${
                      isDragActive ? "active" : ""
                    } border-dropzone`}
                  >
                    <input {...getInputProps()} />
                    <p>
                      {isDragActive
                        ? "Drop the files here..."
                        : "Drag 'n' drop files here, or click to select files"}
                    </p>
                  </div>

                  {/* <input
                    id="inp1"
                    type="file"
                    className="form-control form-input"
                    multiple
                    onChange={(e) => handleDocuments(e)}
                    placeholder="Enter the file"
                  /> */}

                  <p className="error-txt">
                    {selectedFiles.length != 0 ? "" : error.document}
                  </p>

                  {selectedFiles.length > 0 && (
                    <div>
                      {selectedFiles.length > 0 &&
                        selectedFiles.map((file, index) => (
                          <div className="d-flex align-items-center justify-content-between ">
                            {!props.edit && (
                              <p key={index} className="form-label file-names">
                                {file.name}
                              </p>
                            )}
                            {/* {props.edit && (
                              <p key={index} className="form-label file-names">
                                {file}
                              </p>
                            )} */}
                            {props.edit && (
                              <p key={index} className="form-label file-names">
                                {file instanceof File ? file.name : file}
                              </p>
                            )}

                            <span>
                              <RiDeleteBin6Line
                                className="cp-pc"
                                color={"#0d2853"}
                                onClick={(e) => handleRemoveFile(e, index)}
                              />
                            </span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card-footer">
              <button className="footer-btn" onClick={(e) => handleContinue(e)}>
                <span>
                  {isUploadLogo ||
                  isUploadDocuments ||
                  isUploadBanner ||
                  loading
                    ? "Processing"
                    : "Save & Continue"}
                </span>
                <img
                  src={common.loadImg("continue-arrow.svg")}
                  alt="continue-arrow"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
