import React, { useRef, useState } from "react";
import ListTable from "../components/listTable";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AmlHashForm from "aml-hash-form";
import { api } from "../helpers/api";
import { common } from "../helpers/common";
import ViewModal from "../components/viewModal";

const statusOption = [
  { value: "accept", label: "Accept" },
  { value: "preparing", label: "Preparing" },
  { value: "ready", label: "Ready" },
  { value: "completed", label: "Completed" },
  { value: "reject", label: "Reject" },
  { value: "cancel", label: "cancel" },
];

export default function MenuHeadings(props) {
  const childRef = useRef();
  const [loading, setloading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [orderId, setOrderId] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [isView, setIsView] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  const formFields = {
    orderStatus: {
      validate: ["req#Status is required"],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // handler
  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      setloading(true);
      if (orderId) {
        editOrder();
      }
    }
  };

  const onAction = (data) => {
    setOrderId(data.item.id);
    getOrderById(data.item.id);
    if (data.type === "View") {
      setIsView(true);
    } else if (data.type === "Edit") {
      setModalShow(true);
    }
  };

  const getOrderById = (orderId) => {
    setViewLoading(true);
    let getOrderQuery = `query Order($orderId: ID!) {
      order(orderId: $orderId) {
          id
          user {
              id
              name
              email
              avatar
              socialAvatar
              userType
          }
          ... on FoodOrder {
              id
              type
              status
              subTotal
              taxTotal
              grandTotal
              currency
              customerNotes
              orderedAt
              items {
                  id
                  price
                  quantity
                  item {
                      id
                      name
                      description
                      image
                      basePrice
                      taxPercentage
                      isActive
                  }
              }
              payments {
                  id
                  ordermerchantType
                  amount
                  currency
                  paymentStatus
                  paidAt
                  order {
                      id
                      ... on FoodOrder {
                          id
                          type
                          status
                          subTotal
                          taxTotal
                          grandTotal
                          currency
                          customerNotes
                          allowedStatusUpdates
                          orderedAt
                      }
                  }
                  ... on StripePayment {
                      id
                      ordermerchantType
                      amount
                      currency
                      stripePaymentId
                      paymentStatus
                      paidAt
                      paymentMethodType
                      paymentMethodLast4
                      paymentMethodBrand
                      stripeRefundId
                      refundAmount
                      refundStatus
                      refundedAt
                  }
                  ... on WalletPayment {
                      id
                      ordermerchantType
                      amount
                      currency
                      walletPaymentId
                      paymentStatus
                      paidAt
                      walletRefundId
                      refundAmount
                      refundStatus
                      refundedAt
                  }
              }
          }
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: getOrderQuery,
        variables: {
          orderId: orderId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data?.data?.order;
      let arr = [];

      data?.allowedStatusUpdates?.length > 0 &&
        data?.allowedStatusUpdates?.map((item) => {
          let val = { value: item, label: common.Capitalize(item) };
          arr.push(val);

          if (item === data.status) {
            setMultiValue({ orderStatus: val });
          }
        });
      setStatusOptions(arr);

      setSelectedItem(data);
      setViewLoading(false);
    });
  };

  const editOrder = async () => {
    setloading(true);
    // api call
    let editOrderQuery = `mutation UpdateFoodOrderStatus($orderId: ID!, $status: FoodOrderStatusType!) {
      updateFoodOrderStatus(orderId: $orderId, input: { orderStatus: $status }) {
          id
          type
          status
          subTotal
          taxTotal
          grandTotal
          currency
          customerNotes
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: editOrderQuery,
        variables: {
          orderId: orderId,
          status: fields.orderStatus.value,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.updateFoodOrderStatus &&
        Object.keys(data?.data?.updateFoodOrderStatus).length > 0
      ) {
        childRef.current.parentCall();
        resetForm();
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const onselect = (val, name) => {
    setMultiValue({ [name]: val });
  };

  const resetForm = () => {
    setMultiValue({
      orderStatus: "",
    });
    setOrderId(null);
    setloading(false);
  };

  return (
    <>
      <div className="pages-wrapper">
        <div className="page-title-wrap">
          <div>
            <h5 className="page-title-txt">Orders</h5>
          </div>
        </div>
        <ListTable ref={childRef} module="orders" onAction={onAction} />
      </div>
      {/* <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {orderId ? "Update Order" : "Add Order"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Status</label>
                <Select
                  value={fields.orderStatus}
                  name="country"
                  options={statusOptions}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  onChange={(data) => onselect(data, "orderStatus")}
                />
                <p className="error-txt">{errors.orderStatus}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-foot-btn dark"
              onClick={(e) => onSubmit(e)}
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}

      {/* view */}
      {isView && (
        <ViewModal
          loading={viewLoading}
          show={isView}
          item={selectedItem}
          closeModal={() => setIsView(false)}
          module={"order"}
          title={"Orders"}
        />
      )}
    </>
  );
}
