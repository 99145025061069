import { Fragment } from "react";
import FrontHeader from "./frontHeader";
import { Outlet, useNavigate } from "react-router-dom";

const FrontLayout = (props) => {
  return (
    <Fragment>
      <FrontHeader />
      <Outlet {...props} />
    </Fragment>
  );
};

export default FrontLayout;
