import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import { common } from "../helpers/common";
import agModuleList from "../assets/files/agModule.json";
import { api } from "../helpers/api";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Modal from "react-bootstrap/Modal";
import Popover from "react-bootstrap/Popover";
import { IoEllipsisVertical, IoAlertCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { config } from "../helpers/config";
import StatusBar from "./statusBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import { RiDeleteBinLine } from "react-icons/ri";
import Cart from "../pages/cart";
import {
  GetFoodCartDocument,
  GetFoodItemsDocument,
  useAddFoodItemToCartMutation,
  useCreateFoodCartMutation,
  useCreateFoodItemMutation,
  useGetFoodItemQuery,
  useGetFoodItemsQuery,
} from "../gql/generated";

const countryOption = [
  { value: "uk", label: "United Kingdom" },
  { value: "us", label: "United States" },
  { value: "ind", label: "India" },
];

const ListTable = forwardRef((props, ref) => {
  //   Filter Popup
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedCountry, setCountry] = useState();
  // props
  const agModule = agModuleList[props.module];
  const [result, setResult] = useState({
    data: [],
    totalResults: 0,
    totalRecords: 0,
  });
  const [actionData, setActionData] = useState(null);
  const [paging, setPaging] = useState({ offset: 0, limit: 10 });
  const [search, setSearch] = useState("");
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [cartId, setCartId] = useState(0);

  const [itemList, setItemList] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [items, setItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);

  // effect
  useEffect(() => {
    loadData();
    getItemList();
  }, [paging]);

  useImperativeHandle(ref, () => ({
    parentCall: loadData,
  }));

  // const {data: itemData, error: itemError} = useGetFoodItemQuery();
  // console.log("================data", itemData, itemError);

  const handleAddToCart = (item) => {
    props?.onAction({
      type: "Add Items",
      item: item,
      module: props.module,
    });
  };

  const getItemList = () => {
    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: agModuleList["foodItems"].query,
        variables: {
          search: "",
          isActive: true,
          limit: 1000,
          offset: 0,
        },
      },
    };
    // call
    api.call(options, (res) => {
      if (res.status == 200) {
        let data = res?.data?.data?.foodItems?.list;
        let arr = [];
        data?.length > 0 &&
          data?.map((item) => {
            let option = {
              label: item.name,
              value: item.id,
            };
            arr.push(option);
          });
        setItemList(res?.data?.data?.foodItems?.list);
      }
    });
  };

  const loadData = () => {
    setLoading(true);
    // params
    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: agModule.query,
        variables: {
          search: search || "",
          limit: paging.limit,
          offset: paging.offset,
        },
      },
    };
    // call
    api.call(options, (res) => {
      if (res.status == 200) {
        let data = res?.data?.data;

        setResult({
          data: data[props.module].list,
          totalResults: data[props.module].totalCount,
          totalRecords: data[props.module].totalCount,
        });
      }
      setLoading(false);
    });
  };

  // action
  const handleActionCallBack = (i) => {
    if (i == "Delete") {
      setItem({
        type: i,
        item: actionData,
        module: props.module,
      });
      setModalDelete(true);
    } else {
      props?.onAction({
        type: i,
        item: actionData,
        module: props.module,
      });
    }
    setActionData(null);
  };

  // pagination
  const handlePaging = (no) => {
    if (paging.offset != no) {
      setPaging((prev) => ({ ...prev, offset: (no - 1) * paging.limit }));
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPaging({ offset: 0, limit: 10 });
  };

  const onDelete = () => {
    props?.onAction(item);
    setModalDelete(false);
  };

  // refresh
  const handleRefresh = () => {
    setPaging({ offset: 1, limit: 10 });
    // action
    setActionData(null);
    // filter
    setSearch("");
  };

  const onselect = (data) => {
    setItems(data);
  };

  // get appllo query
  const {
    data: foodItems,
    error: foodItemErrors,
    refetch,
  } = useGetFoodItemsQuery({
    variables: {
      isActive: true,
    },
  });

  useEffect(() => {
    refetch({ search: "food" });
  }, []);

  return (
    <div className="list-table-wrapper">
      <div className="list-table-header">
        {agModule?.search && (
          <form autoComplete="off">
            <div className="search-input-wrap">
              <img
                src={common.loadImg("search-icn.svg")}
                alt="icon"
                width="20"
                height="20"
              />

              <input
                type="text"
                // name="search"
                id="search"
                value={search}
                placeholder={agModule?.search}
                className="search-input"
                onChange={handleSearch}
                name="hidden"
              />
            </div>
          </form>
        )}
        <div>
          {agModule.refresh && (
            <button className="btn-filter" onClick={handleRefresh}>
              <RefreshIcon />
            </button>
          )}
          <button className="table-header-btn">Sort</button>
          <button className="table-header-btn" onClick={handleShow}>
            Filter
          </button>
        </div>
      </div>
      {loading ? (
        <StatusBar width={100} height={100} />
      ) : (
        <>
          {result.data.length == 0 ? (
            <StatusBar width={200} height={200} status={"nodata"} />
          ) : (
            <div className="table-wrapper">
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>#</th>
                    {agModule?.fields &&
                      Object.entries(agModule?.fields).map(([key, item]) => {
                        return <th key={key}>{item.label}</th>;
                      })}
                    {props?.module == "foodCarts" && <th>Action</th>}
                    {agModule?.action.length > 0 && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {result?.data?.length > 0 &&
                    result?.data.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        {Object.entries(agModule?.fields).map(
                          ([cKey, cItem]) => (
                            <>
                              <td
                                className={
                                  cItem.type === "desc" ? "desc-text" : ""
                                }
                              >
                                {cItem.type === "image" ? (
                                  <img
                                    src={config.api.cloudImgPath + item[cKey]}
                                    alt="Brand Logo"
                                    className="menu-img"
                                  />
                                ) : cItem.type === "status" ? (
                                  item.isActive ? (
                                    "Active"
                                  ) : (
                                    "Deactive"
                                  )
                                ) : cItem.type === "userObj" ? (
                                  // item?.user[cKey]
                                  item?.user?.[cKey]
                                ) : cItem.type === "orderObj" ? (
                                  cItem.val == "date" ? (
                                    moment(item.order[cKey]).format(
                                      "ll HH:mm A"
                                    )
                                  ) : (
                                    item.order[cKey]
                                  )
                                ) : cItem.type == "allowOrderManagement" ? (
                                  item.allowOrderManagement == true ? (
                                    "Yes"
                                  ) : (
                                    "No"
                                  )
                                ) : cItem.type == "allowMenuManagement" ? (
                                  item.allowMenuManagement == true ? (
                                    "Yes"
                                  ) : (
                                    "No"
                                  )
                                ) : cItem.type === "merchantObj" ? (
                                  item["merchant"].name
                                ) : cItem.type === "date" ? (
                                  item[cKey] ? (
                                    moment(item[cKey]).format("ll HH:mm A")
                                  ) : (
                                    " - "
                                  )
                                ) : // Object.prototype.toString.call(item[cKey]) ==
                                // "[object String]" ? (
                                //   moment(item[cKey]).format("ll") +
                                //   "_" +
                                // moment(item[cKey]).format("HH:mm A")
                                // ) : (
                                //   "-"
                                // )
                                cItem.type === "imageObj" ? (
                                  <img
                                    src={
                                      config.api.cloudImgPath +
                                      item["merchant"].image
                                    }
                                    alt="Brand Logo"
                                    className="menu-img"
                                  />
                                ) : (
                                  item[cKey]
                                )}
                              </td>
                            </>
                          )
                        )}

                        {props.module == "foodCarts" && (
                          <td>
                            <button
                              onClick={() => {
                                // addToCart(item.id);
                                handleAddToCart(item);
                              }}
                              // disabled={item?.existsInCart == true}
                              className="add-cart-btn"
                            >
                              Add Items
                            </button>
                          </td>
                        )}
                        {agModule?.action?.length > 0 && (
                          <td>
                            <OverlayTrigger
                              trigger={"click"}
                              placement={"bottom"}
                              containerPadding={5}
                              rootClose={true}
                              rootCloseEvent={"click"}
                              onToggle={() =>
                                actionData ? setActionData(null) : null
                              }
                              show={actionData?.id == item.id}
                              overlay={
                                <Popover id="popover-contained">
                                  <Popover.Header as="h3">
                                    Actions
                                  </Popover.Header>
                                  <Popover.Body className="p-0">
                                    <ul className="page-count-list">
                                      {agModule?.action.map((i, k) => (
                                        <li
                                          key={k}
                                          onClick={() =>
                                            handleActionCallBack(i)
                                          }
                                        >
                                          <Link to="">{i}</Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <div className="action-btn-wrap">
                                <button
                                  className="action-btn"
                                  onClick={() =>
                                    setTimeout(() => {
                                      setActionData(item);
                                    }, 50)
                                  }
                                >
                                  <IoEllipsisVertical
                                    onClick={() =>
                                      setTimeout(() => {
                                        setActionData(item);
                                      }, 50)
                                    }
                                  />
                                </button>
                              </div>
                            </OverlayTrigger>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
      <div className="table-pagination-wrap">
        <div>
          <p className="pagination-show-txt">
            Showing {paging.offset === 0 ? paging.offset + 1 : paging.offset} to{" "}
            {(paging.offset + 1) * paging.limit >= result.totalResults
              ? result.totalResults
              : paging.offset === 0
              ? (paging.offset + 1) * paging.limit
              : paging.offset}{" "}
            of {result.totalResults} entries
          </p>
        </div>
        <div>
          {result.totalResults > 0 && (
            <Pagination
              activePage={paging.offset}
              itemsCountPerPage={paging.limit}
              totalItemsCount={result.totalResults}
              pageRangeDisplayed={5}
              onChange={handlePaging}
            />
          )}
        </div>
      </div>
      <Offcanvas show={show} placement="end" onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton className="filter-wrap-header">
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <label className="form-label">Textbox</label>
          <input
            type="text"
            name="banner_name"
            className="form-control form-input"
          />
          <label className="form-label">Country</label>
          <Select
            value={selectedCountry}
            name="country"
            options={countryOption}
            className="react-select-container"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: "#3073be",
                padding: "8px",
                className: "select-control",
              }),
            }}
          />
          <button className="filter-submit-btn">Submit</button>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Delete Popup */}
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalDelete}
        onHide={() => setModalDelete(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="w-100 text-center"
          >
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12 text-center">
                <IoAlertCircleOutline className="delete-alert-icn" />
                <p>Are you sure want to delete?</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalDelete(false)}
            >
              Cancel
            </button>
            <button className="modal-foot-btn dark" onClick={() => onDelete()}>
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default ListTable;
