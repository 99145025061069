import React from "react";
import { useState } from "react";
// includes
import { common } from "../../helpers/common";

export default function Step2(props) {
  const [selected, setSelected] = useState([]);
  const [profileData, setProfileData] = useState(
    common.localGet("profileData")
      ? JSON.parse(common.localGet("profileData"))
      : {}
  );
  const handleContinue = () => {
    if (selected.length != 0) {
      let oldProfile = profileData;
      let newArr = { ...oldProfile, ...{ shop: selected } };
      common.localSet("profileData", newArr);
      props.onSuccess();
    } else {
      common.notify("E", "Please Select a type of restaurant or shop");
    }
  };
  const handleSelect = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "dine_in&take_away") {
      setSelected(["dine_in", "take_away"]);
    } else {
      setSelected(null);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="profile-steps-card">
            <div className="card-header pb-0">
              {/* <a href="" className="back-btn-wrap">
                <img src={common.loadImg("back-arrow.svg")} alt="back-arrow" />
                <span>Back</span>
              </a> */}
              <div className="title-wrap">
                <h3 className="title">Select type of restaurant or shop</h3>
                <p className="desc">You can select only one</p>
              </div>
            </div>
            <div className="card-body shop-category-wrap pt-1">
              <ul className="row">
                {/* <li className="col-md-7 mx-auto">
                  <input
                    type="radio"
                    id="storeshop"
                    value="storeshop"
                    name="shopCategory"
                    className="shop-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label htmlFor="storeshop" className="shop-input-label">
                    Store/Shop
                  </label>
                </li>
                <li className="col-md-7 mx-auto">
                  <input
                    type="radio"
                    id="cafebakes"
                    value="cafebakes"
                    name="shopCategory"
                    className="shop-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label htmlFor="cafebakes" className="shop-input-label">
                    Cafe/Bakes
                  </label>
                </li> */}
                <li className="col-md-7 mx-auto">
                  <input
                    type="radio"
                    id="dinning"
                    value="dine_in"
                    name="shopCategory"
                    className="shop-category"
                    onChange={(e) => setSelected([e.target.value])}
                  />
                  <label htmlFor="dinning" className="shop-input-label">
                    Dining
                  </label>
                </li>
                <li className="col-md-7 mx-auto">
                  <input
                    type="radio"
                    id="Takeaway"
                    value="take_away"
                    name="shopCategory"
                    className="shop-category"
                    onChange={(e) => setSelected([e.target.value])}
                  />
                  <label htmlFor="Takeaway" className="shop-input-label">
                    Takeaway
                  </label>
                </li>
                <li className="col-md-7 mx-auto">
                  <input
                    type="radio"
                    id="dinningtakeaway"
                    value="dine_in&take_away"
                    name="shopCategory"
                    className="shop-category"
                    onChange={(e) => handleSelect(e)}
                  />
                  <label htmlFor="dinningtakeaway" className="shop-input-label">
                    Dining & Takeaway
                  </label>
                </li>
              </ul>
            </div>
            <div className="card-footer">
              <button className="footer-btn" onClick={handleContinue}>
                <span>Continue</span>
                <img
                  src={common.loadImg("continue-arrow.svg")}
                  alt="continue-arrow"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
