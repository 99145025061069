import { Fragment, useEffect, useState } from "react";
import InnerHeader from "./innerHeader";
import { Outlet, useLocation, NavLink, useNavigate } from "react-router-dom";
// includes
import { common } from "../helpers/common";
import { api } from "../helpers/api";
import { MdOutlineNoFood } from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";
import { config } from "../helpers/config";
import { useFoodOrderNotificationSubscription } from "../gql/generated";
import { Modal } from "react-bootstrap";
import { closeSnackbar } from "notistack";

const InnerLayout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSubmenu1, setIsSubmenu1] = useState(false);
  const [isSubmenu2, setIsSubmenu2] = useState(false);
  const [user, setUser] = useState(false);
  const [popupOrder, setPopupOrder] = useState(null);
  const [isModal, setIsModal] = useState(true);
  const { data, error } = useFoodOrderNotificationSubscription();

  const action = (snackbarId) => {
    return (
      <>
        <button className="snack-btn" onClick={() => closeSnack(snackbarId)}>
          Open
        </button>

        <button
          className="snack-btn-close"
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          Close
        </button>
      </>
    );
  };

  const closeSnack = (snackbarId) => {
    navigate(`/orders`);
    closeSnackbar(snackbarId);
  };

  useEffect(() => {
    if (
      data?.foodOrderNotification &&
      data?.foodOrderNotification?.type == "new"
    ) {
      setPopupOrder(data.foodOrderNotification.order);
      common.notify("S", "You have recevied a new order", action);
      setIsModal(true);
      const timer = setTimeout(() => {
        setPopupOrder(null);
        setIsModal(false);
      }, 30000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [data]);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    // api call
    let userQuery = `query CurrentUser {
      currentUser {
          id
          name
          email
          avatar
          socialAvatar
          userType
          profile {
              __typename
              ... on FoodMerchantPrivate {
                  id
                  name
                  description
                  image
                  bannerImage
                  phone
                  email
                  country
                  addressLine1
                  addressLine2
                  city
                  state
                  postalCode
                  businessRegistrationNumber
                  businessDocuments
                  vatId
                  orderTypes
                  websiteUrl
                  stripeAccountStatus
                  isActive
                  location {
                      lat
                      lng
                  }
                  operatingDays {
                      id
                      weekDay
                      weekDayName
                      isOpen
                      openingTime
                      closingTime
                  }
              }
            ... on FoodSubMerchant {
              id
              name
              username
              designation
              allowMenuManagement
              allowOrderManagement
              isActive
              parent {
                id
                name
                description
                image
                bannerImage
              }
            }
          }
      }
    }
  `;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: userQuery,
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;

      setUser(data?.data?.currentUser);
    });
  };

  const closeModal = () => {
    setIsModal(false);
    setPopupOrder(null);
  };

  const orderView = () => {
    navigate(`/orders/${"FoodOrder_78"}`);
    setIsModal(false);
    setPopupOrder(null);
  };
  console.log("sdfdfdfdfdfdfdfdf", user?.profile?.allowMenuManagement);

  return (
    <Fragment>
      {/* <Modal
        show={popupOrder ? true : false}
        onHide={() => closeModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Notification
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
			You have recevied a new order, Order Id : {popupOrder?.id}
		</Modal.Body>
		<Modal.Footer>
        <div className="modal-btn-container">
          <button
            className="modal-foot-btn light"
            onClick={() => orderView()}
          >
            GoTo Order
          </button>
        </div>
      </Modal.Footer>
      </Modal> */}
      <InnerHeader />
      <div className="inner-wrapper">
        <div className="sidebar">
          {user?.userType == "SUB_MERCHANT" && (
            <div className="logo-wrapper">
              <img
                src={config.api.cloudImgPath + user?.profile?.parent?.image}
                alt="logo"
                className="sidebar-logo"
              />
              <h6 className="brand-name">{user?.profile?.name}</h6>
            </div>
          )}
          {user?.userType != "ADMINISTRATOR" &&
            user?.userType != "SUB_MERCHANT" && (
              <div className="logo-wrapper">
                <img
                  src={config.api.cloudImgPath + user?.profile?.image}
                  alt="logo"
                  className="sidebar-logo"
                />
                <h6 className="brand-name">{user?.profile?.name}</h6>
              </div>
            )}
          {user?.userType != "ADMINISTRATOR" && (
            <ul className="sidebar-menu">
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/dashboard" ? (
                    <img
                      src={common.loadImg("dashboard-active-icn.svg")}
                      alt="logo"
                      className="menu-icn"
                    />
                  ) : (
                    <img
                      src={common.loadImg("dashboard-icn.svg")}
                      alt="logo"
                      className="menu-icn"
                    />
                  )}

                  <span>Dashboard</span>
                </NavLink>
              </li>
              {user?.userType == "MERCHANT" ||
              (user?.userType == "SUB_MERCHANT" &&
                user?.profile?.allowOrderManagement == true) ? (
                <li>
                  <NavLink
                    to="/orders"
                    className={({ isActive }) =>
                      isActive ? "active-menu" : "default-menu"
                    }
                  >
                    {location.pathname == "/orders" ? (
                      <img
                        src={common.loadImg("orders-active-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    ) : (
                      <img
                        src={common.loadImg("orders-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    )}
                    <span>Orders</span>
                  </NavLink>
                </li>
              ) : null}

              {user?.userType == "MERCHANT" ||
              (user?.userType == "SUB_MERCHANT" &&
                user?.profile?.allowMenuManagement == true) ? (
                <li>
                  <a
                    className="sidebar-submenu-link"
                    onClick={() => setIsSubmenu1(!isSubmenu1)}
                  >
                    {location.pathname == "/menuheadings" ||
                    location.pathname == "/menuitems" ? (
                      <img
                        src={common.loadImg("menu-active-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    ) : (
                      <img
                        src={common.loadImg("menu-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    )}
                    <span
                      className={
                        location.pathname == "/menuheadings" ||
                        location.pathname == "/menuitems"
                          ? "active-menu"
                          : "default-menu"
                      }
                    >
                      Menu
                    </span>
                  </a>
                  {isSubmenu1 && (
                    <ul className="sidebar-submenu">
                      <li>
                        <NavLink to="/menuheadings">
                          <span
                            className={
                              location.pathname == "/menuheadings"
                                ? "active-menu"
                                : "default-menu"
                            }
                          >
                            Menu
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/menuitems">
                          <span
                            className={
                              location.pathname == "/menuitems"
                                ? "active-menu"
                                : "default-menu"
                            }
                          >
                            Items
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
              ) : null}

              {user?.userType != "SUB_MERCHANT" && (
                <li>
                  <NavLink
                    to="/transfers"
                    className={({ isActive }) =>
                      isActive ? "active-menu" : "default-menu"
                    }
                  >
                    {location.pathname == "/transfers" ? (
                      <MdOutlineNoFood size={25} />
                    ) : (
                      <MdOutlineNoFood size={25} />
                    )}
                    <span
                      className={
                        location.pathname == "/transfers"
                          ? "active-menu ms-2"
                          : "default-menu ms-2"
                      }
                    >
                      Transfers
                    </span>
                  </NavLink>
                </li>
              )}
              {user?.userType == "MERCHANT" ||
              (user?.userType == "SUB_MERCHANT" &&
                user?.profile?.allowOrderManagement == true) ? (
                <li>
                  <NavLink
                    to="/cart"
                    className={({ isActive }) =>
                      isActive ? "active-menu" : "default-menu"
                    }
                  >
                    {location.pathname == "/cart" ? (
                      <img
                        src={common.loadImg("orders-active-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    ) : (
                      <img
                        src={common.loadImg("orders-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    )}
                    <span>Food Cart</span>
                  </NavLink>
                </li>
              ) : null}
              {user?.userType != "SUB_MERCHANT" && (
                <li>
                  <NavLink
                    to="/submerchant"
                    className={({ isActive }) =>
                      isActive ? "active-menu" : "default-menu"
                    }
                  >
                    {location.pathname == "/submerchant" ? (
                      <img
                        src={common.loadImg("orders-active-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    ) : (
                      <img
                        src={common.loadImg("orders-icn.svg")}
                        alt="logo"
                        className="menu-icn"
                      />
                    )}
                    <span>Sub Merchant</span>
                  </NavLink>
                </li>
              )}

              {/* <li>
                <a href="">
                  <img
                    src={common.loadImg("outlets-icn.svg")}
                    alt="logo"
                    className="menu-icn"
                  />
                  <span>Outlets</span>
                </a>
              </li>
              <li>
                <a href="">
                  <img
                    src={common.loadImg("reviews-icn.svg")}
                    alt="logo"
                    className="menu-icn"
                  />
                  <span>Reviews</span>
                </a>
              </li>
              <li>
                <a
                  className="sidebar-submenu-link"
                  onClick={() => setIsSubmenu2(!isSubmenu2)}
                >
                  <img
                    src={common.loadImg("reports-icn.svg")}
                    alt="logo"
                    className="menu-icn"
                  />
                  <span>Reports</span>
                </a>
                {isSubmenu2 && (
                  <ul className="sidebar-submenu">
                    <li>
                      <a href="">
                        <span>Sales Reports</span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span>Expense Reports</span>
                      </a>
                    </li>
                  </ul>
                )}
              </li> */}
            </ul>
          )}
          {user?.userType == "ADMINISTRATOR" && (
            <ul className="sidebar-menu">
              <li>
                <NavLink
                  to="/cuisines"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/cuisines" ? (
                    <MdOutlineNoFood size={25} />
                  ) : (
                    <MdOutlineNoFood size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/cuisines"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    Cuisines
                  </span>
                </NavLink>
              </li>

              <li>
                <a
                  className="sidebar-submenu-link"
                  onClick={() => setIsSubmenu1(!isSubmenu1)}
                >
                  {location.pathname == "/merchant" ? (
                    <IoStorefrontOutline size={25} color={"#0d2853"} />
                  ) : (
                    <IoStorefrontOutline size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/merchant"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    Merchant
                  </span>
                </a>
                {isSubmenu1 && (
                  <ul className="sidebar-submenu">
                    <li>
                      <NavLink to="/merchant">
                        <span
                          className={
                            location.pathname == "/merchant"
                              ? "active-menu"
                              : "default-menu"
                          }
                        >
                          Food Merchant
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/merchant">
                        <span
                          className={
                            location.pathname == "/merchant"
                              ? "active-menu "
                              : "default-menu "
                          }
                        >
                          Shop Merchant
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/merchant">
                        <span
                          className={
                            location.pathname == "/merchant"
                              ? "active-menu "
                              : "default-menu"
                          }
                        >
                          Service Merchant
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <NavLink
                  to="/adminorders"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/adminorders" ? (
                    <MdOutlineNoFood size={25} />
                  ) : (
                    <MdOutlineNoFood size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/adminorders"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    Orders
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/payment"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/payment" ? (
                    <MdOutlineNoFood size={25} />
                  ) : (
                    <MdOutlineNoFood size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/payment"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    Payment
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/transfers"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/transfers" ? (
                    <MdOutlineNoFood size={25} />
                  ) : (
                    <MdOutlineNoFood size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/transfers"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    Transfers
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/roles"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/roles" ? (
                    <MdOutlineNoFood size={25} />
                  ) : (
                    <MdOutlineNoFood size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/roles"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    User and Roles
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/analytics"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/analytics" ? (
                    <MdOutlineNoFood size={25} />
                  ) : (
                    <MdOutlineNoFood size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/analytics"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    Analytics
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/complaints"
                  className={({ isActive }) =>
                    isActive ? "active-menu" : "default-menu"
                  }
                >
                  {location.pathname == "/complaints" ? (
                    <MdOutlineNoFood size={25} />
                  ) : (
                    <MdOutlineNoFood size={25} />
                  )}
                  <span
                    className={
                      location.pathname == "/complaints"
                        ? "active-menu ms-2"
                        : "default-menu ms-2"
                    }
                  >
                    Complaints
                  </span>
                </NavLink>
              </li>
            </ul>
          )}
        </div>
        <div className="right-wrapper">
          <Outlet {...props} />
        </div>
      </div>
    </Fragment>
  );
};

export default InnerLayout;
