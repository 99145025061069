import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../assets/css/dashboard.css";
import { common } from "../helpers/common";
import { api } from "../helpers/api";
import { config } from "../helpers/config";
export default function Dashboard() {
  const now = 100;
  const [user, setUser] = useState([]);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    // api call
    // let userQuery = `query GetCurrentUser {
    //   currentUser {
    //     __typename
    //     id
    //     name
    //     email
    //     avatar
    //     socialAvatar
    //     userType
    //     ... on FoodMerchantUser {
    //       profile {
    //         __typename
    //         id
    //          name
    //          image
    //          bannerImage
    //         description
    //         websiteUrl
    //         orderTypes
    //         country
    //         addressLine1
    //         addressLine2
    //         city
    //         state
    //         postalCode
    //         location {
    //           lat
    //           lng
    //         }
    //         businessRegistrationNumber
    //         businessDocuments
    //         vatId
    //         orderTypes
    //         operatingDays {
    //           id
    //           weekDay
    //           weekDayName
    //           isOpen
    //           openingTime
    //           closingTime
    //         }
    //         stripeAccountStatus
    //       }
    //     }
    //   }
    // }`;
    let userQuery = `query CurrentUser {
      currentUser {
          id
          name
          email
          avatar
          socialAvatar
          userType
          profile {
              __typename
              ... on FoodMerchantPrivate {
                  id
                  name
                  description
                  image
                  bannerImage
                  phone
                  email
                  country
                  addressLine1
                  addressLine2
                  city
                  state
                  postalCode
                  businessRegistrationNumber
                  businessDocuments
                  vatId
                  orderTypes
                  websiteUrl
                  stripeAccountStatus
                  isActive
                  location {
                      lat
                      lng
                  }
                  operatingDays {
                      id
                      weekDay
                      weekDayName
                      isOpen
                      openingTime
                      closingTime
                  }
              }
            ... on FoodSubMerchant {
              id
              name
              username
              designation
              allowMenuManagement
              allowOrderManagement
              isActive
              parent {
                id
                name
                description
                image
                bannerImage
              }
            }
          }
      }
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: userQuery,
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;

      setUser(data?.data?.currentUser);
    });
  };
  return (
    <>
      <div className="dashboard-header">
        <div className="left">
          {user.userType == "SUB_MERCHANT" ? (
            <img
              src={config.api.cloudImgPath + user?.profile?.parent?.image}
              alt="logo"
              width="70"
              height="70"
            />
          ) : (
            <img
              src={config.api.cloudImgPath + user?.profile?.image}
              alt="logo"
              width="70"
              height="70"
            />
          )}
        </div>
        <div className="right">
          <p className="status-label">Account Status</p>
          <p className="branch-name">{user?.profile?.name}</p>
          <p className="progress-wrap">
            <ProgressBar now={now} />
            <span className="label">{`${now}%`}</span>
          </p>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <h5>Dashboard</h5>
      </div>
    </>
  );
}
