import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// layouts
import FrontLayout from './layouts/frontLayout';
import InnerLayout from './layouts/innerLayout.js';
//Pages
import SignIn from './pages/auth/signin.js';
import SignUp from './pages/auth/signup.js';
import Thanks from './pages/auth/thanks.js';
import Dashboard from './pages/dashboard.js';
import ForgotPassword from './pages/auth/forgotPassword.js';
import OtpVerification from './pages/auth/otpVerification.js';
import ResetPassword from './pages/auth/resetPassword.js';
import ProfileSetup from './pages/auth/profileSetup.js';
import Orders from './pages/orders.js';
import { SnackbarProvider } from 'notistack';
import MenuHeadings from './pages/menus/menuHeadings.js';
import MenuItems from './pages/menus/menuItems.js';
import Step8 from './components/profileSetup/step8.js';
import Poc from './pages/poc.js';
import Cuisines from './admin/cuisines.js';
import Merchant from './admin/merchant.js';
import Analytics from './admin/analytics.js';
import Complaints from './admin/complaints.js';
import Payment from './admin/payment.js';
import UserRoles from './admin/userRoles.js';
import AdminOrders from './admin/adminOrders.js';
import Transfers from './admin/transfers.js';
import Cart from './pages/cart.js';
import { ApolloProvider } from '@apollo/client';
// import { apolloClient } from './gql/client';
import { apolloClient } from './gql/client.ts';
import Submerchant from './pages/sub-merchant.js';

function App() {
	return (
		<ApolloProvider client={apolloClient}>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Router>
					<Routes>
						<Route path='/' element={<FrontLayout />}>
							<Route path='/' element={<SignIn />} />
							<Route path='/login' element={<SignIn />} />
							<Route path='/register' element={<SignUp />} />
							<Route path='/forgotpassword' element={<ForgotPassword />} />
							<Route path='/otpverification' element={<OtpVerification />} />
							<Route path='/resetpassword' element={<ResetPassword />} />
							<Route path='/thanks' element={<Thanks />} />
							<Route path='/profilesetup' element={<ProfileSetup />} />
						</Route>
						<Route path='/' element={<InnerLayout />}>
							<Route path='/' element={<Dashboard />} />
							<Route path='/dashboard' element={<Dashboard />} />
							<Route path='/orders' element={<Orders />} />
							<Route path='/orders/:id' element={<Orders />} />
							<Route path='/menuheadings' element={<MenuHeadings />} />
							<Route path='/menuitems' element={<MenuItems />} />
							<Route path='/profile' element={<Step8 />} />
							<Route path='/poc' element={<Poc />} />
							<Route path='/cuisines' element={<Cuisines />} />
							<Route path='/merchant' element={<Merchant />} />
							<Route path='/adminorders' element={<AdminOrders />} />
							<Route path='/analytics' element={<Analytics />} />
							<Route path='/payment' element={<Payment />} />
							<Route path='/complaints' element={<Complaints />} />
							<Route path='/roles' element={<UserRoles />} />
							<Route path='/transfers' element={<Transfers />} />
							<Route path='/cart' element={<Cart />} />
							<Route path='/submerchant' element={<Submerchant/>} />
						</Route>
					</Routes>
				</Router>
			</SnackbarProvider>
		</ApolloProvider>
	);
}

export default App;
