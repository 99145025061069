import React, { useEffect } from "react";
import AmlHashForm from "aml-hash-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../helpers/api";
import { common } from "../../helpers/common";

export default function SignIn() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });

  // form
  const formFields = {
    usernameOrEmail: {
      validate: ["req#Field is required"],
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      Login();
    }
  };

  // Api
  const Login = async () => {
    setloading(true);
    // api call
    // let loginQuery = `mutation Login($email: String!, $password: String!) {
    //   login(input: {email: $email, password: $password}) {
    //     accessToken
    //     refreshToken
    //   }
    // }`;
    let loginQuery = `mutation Login($usernameOrEmail: String!, $password: String!) {
  login(input: {usernameOrEmail: $usernameOrEmail, password: $password}) {
    accessToken
    refreshToken
  }
}`;
    let options = {
      url: "base_url",
      method: "POST",
      auth: "none",
      body: {
        query: loginQuery,
        variables: {
          usernameOrEmail: fields.usernameOrEmail,
          password: fields.password,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.errors) {
        data?.errors?.length > 0 &&
          data?.errors?.map((item) => {
            if (item?.extensions?.inputErrors?.email === "NOT_FOUND") {
              setError((prev) => ({ ...prev, email: "Invalid Email" }));
            } else {
              setError((prev) => ({ ...prev, password: "Invalid Password" }));
            }
          });

        setloading(false);
      } else {
        if (data?.data?.login) {
          common.localSet("authData", common.crypt(data?.data?.login, true));
          setTimeout(() => {
            getCurrentUser();
          }, 1000);
        }
      }
    });
  };

  const createStripe = () => {
    // api call
    let stripeQuery = `query GetStripeAccountLink($input: StripeAccountLinkInput!) {
      stripeAccountLink(input: $input) {
        url
        created
        expiresAt
      }
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: stripeQuery,
        variables: {
          input: {
            type: "account_onboarding",
            collect: "eventually_due",
            refreshUrl: window.location.origin,
            returnUrl: window.location.origin + "/dashboard",
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let response = res?.data;
      if (response?.data?.stripeAccountLink) {
        window.location.replace(response?.data?.stripeAccountLink?.url);
      }
    });
  };
  const getCurrentUser = async () => {
    // api call

    //     let userQuery = `query CurrentUser {
    //   currentUser {
    //       id
    //       name
    //       email
    //       avatar
    //       socialAvatar
    //       userType
    //       profile {
    //           __typename
    //           ... on FoodMerchantPrivate {
    //               id
    //               name
    //               description
    //               image
    //               bannerImage
    //               phone
    //               email
    //               country
    //               addressLine1
    //               addressLine2
    //               city
    //               state
    //               postalCode
    //               businessRegistrationNumber
    //               businessDocuments
    //               vatId
    //               orderTypes
    //               websiteUrl
    //               stripeAccountStatus
    //               isActive
    //               location {
    //                   lat
    //                   lng
    //               }
    //               operatingDays {
    //                   id
    //                   weekDay
    //                   weekDayName
    //                   isOpen
    //                   openingTime
    //                   closingTime
    //               }
    //           }
    //       }
    //   }
    // }`;
    let userQuery = `query CurrentUser {
  currentUser {
      id
      name
      email
      avatar
      socialAvatar
      userType
      profile {
          __typename
          ... on FoodMerchantPrivate {
              id
              name
              description
              image
              bannerImage
              phone
              email
              country
              addressLine1
              addressLine2
              city
              state
              postalCode
              businessRegistrationNumber
              businessDocuments
              vatId
              orderTypes
              websiteUrl
              stripeAccountStatus
              isActive
              location {
                  lat
                  lng
              }
              operatingDays {
                  id
                  weekDay
                  weekDayName
                  isOpen
                  openingTime
                  closingTime
              }
          }
        ... on FoodSubMerchant {
          id
          name
          username
          designation
          allowMenuManagement
          allowOrderManagement
          isActive
          parent {
            id
            name
            description
            image
            bannerImage
          }
        }
      }
  }
}`;
    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: userQuery,
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.currentUser?.userType != "ADMINISTRATOR" &&
        data?.data?.currentUser?.userType != "SUB_MERCHANT"
      ) {
        if (data?.data?.currentUser?.profile) {
          if (
            data?.data?.currentUser?.profile?.stripeAccountStatus ==
              "complete" ||
            data?.data?.currentUser?.profile?.stripeAccountStatus ==
              "pending enablement"
          ) {
            navigate("/dashboard", { replace: true });
          } else {
            createStripe();
          }
        } else {
          navigate("/profilesetup", { replace: true });
        }
      } else if (data?.data?.currentUser?.userType == "SUB_MERCHANT") {
        navigate("/dashboard", { replace: true });
      } else {
        navigate("/cuisines", { replace: true });
      }
      setloading(false);
    });
  };
  // support
  const resetError = (name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <div className="auth-content-wrap signin-content-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div className="auth-form-card">
              <h3 className="title">Sign In</h3>
              <label className="label">Email or Username</label>
              <input
                type="text"
                className="form-control input"
                placeholder="Enter the email"
                name="usernameOrEmail"
                onChange={(e) => {
                  resetError("email");
                  handleChange(e);
                }}
                maxLength={40}
                value={fields?.usernameOrEmail}
              />
              <p className="error-txt">
                {error?.usernameOrEmail
                  ? error?.usernameOrEmail
                  : errors?.usernameOrEmail}
              </p>
              <label className="label">Password</label>
              <input
                type="password"
                className="form-control input"
                placeholder="Password"
                name="password"
                onChange={(e) => {
                  resetError("password");
                  handleChange(e);
                }}
                value={fields?.password}
              />
              <p className="error-txt">
                {error?.password ? error?.password : errors?.password}
              </p>
              <p className="d-flex justify-content-end mb-0">
                <a href="/forgotpassword" className="plain-link">
                  Forgot Password?
                </a>
              </p>
              <button className="auth-btn w-100" onClick={(e) => onSubmit(e)}>
                {loading ? "Processing" : "Sign In"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
