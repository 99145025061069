import React, { useRef, useState, useEffect } from "react";
import { common } from "../helpers/common";
import ListTable from "../components/listTable";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AmlHashForm from "aml-hash-form";
import { api } from "../helpers/api";
import ViewModal from "../components/viewModal";
import Table from "react-bootstrap/Table";
import agModuleList from "../assets/files/agModule.json";
import { config } from "../helpers/config";
import { RiDeleteBinLine } from "react-icons/ri";

// import { useCreateFoodCartMutation } from "../gql/generated";
import {
  GetFoodCartDocument,
  GetFoodItemsDocument,
  UpdateFoodCartDocument,
  useAddFoodItemToCartMutation,
  useCreateFoodCartMutation,
  useCreateFoodItemMutation,
  useDeleteFoodCartItemMutation,
  useDeleteFoodCartMutation,
  useGetFoodCartQuery,
  useGetFoodItemQuery,
  useGetFoodItemsQuery,
  useUpdateFoodCartItemMutation,
  useUpdateFoodCartMutation,
} from "../gql/generated";

const statusOption = [
  { value: true, label: "Active" },
  { value: false, label: "Deactive" },
];

export default function Cart(props) {
  const childRef = useRef();
  const [loading, setloading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedStatus, setStatus] = useState();
  const [menuId, setMenuId] = useState(null);
  const [isView, setIsView] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [viewLoading, setViewLoading] = useState(false);
  const [itemPopup, setItemPopup] = useState(false);
  const [cartId, setCartId] = useState(0);

  const [itemList, setItemList] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [items, setItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemsIncart, setItemsInCart] = useState([]);
  const [editQuantity, setEditQuantity] = useState({});
  const [search, setSearch] = useState("");

  const formFields = {
    name: {
      validate: ["req#Name is required"],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      if (menuId) {
        editFoodCart(menuId);
      } else {
        createMenu();
      }
    }
  };
  const open = (data) => {
    setItemPopup(true);
  };
  const close = () => {
    setItemPopup(false);
  };
  const onSave = () => {
    const isValid = handleSubmit();

    if (isValid) {
      if (menuId) {
        editFoodCart(menuId);
      } else {
        createFoodCart();
      }
    }
  };
  const onAction = (data) => {
    setMenuId(data.item.id);
    // getCartById(data.item.id);
    setSelectedItem(data.item);
    if (data.type === "View") {
      setIsView(true);
    } else if (data.type === "Edit") {
      setMultiValue({ name: data.item.customerName });
      setItemsInCart(data.item);
      setModalShow(true);

      data?.item?.items?.map((value) => {
        setEditQuantity((prevQuantities) => ({
          ...prevQuantities,
          [value.id]: value.quantity,
        }));
      });
    } else if (data.type === "Delete") {
      deleteFoodCart(data.item.id);
    } else if (data.type == "Add Items") {
      setItemPopup(true);

      // let filterCart = data?.item?.items?.map((value) => {
      //   return value.item.id;
      // });

      let filterCart = data?.item?.items?.map((value) => {
        setQuantities((prevQuantities) => ({
          ...prevQuantities,
          [value.item.id]: value.quantity,
        }));
        return value.item.id;
      });
      // const itemIds = filterCart.flatMap((order) =>
      //   order.items.map((item) => item.item.id)
      setFilteredItems(filterCart);
    }
  };

  const [createFoodCartMutate, { data, error, loading: cartLoad }] =
    useCreateFoodCartMutation({
      onCompleted: (data) => {
        if (data.createFoodCart) {
          childRef.current.parentCall();
          setMultiValue({ name: "" });
          common.notify("S", "Cart Added");
          setModalShow(false);
        }
      },
      onError: (error) => {
        common.notify("E", error.message);
      },
      refetchQueries: [
        // {
        //   query: GetFoodCartDocument,
        //   variables: {
        //     cartId: menuId,
        //   },
        // },
        GetFoodCartDocument,
      ],
    });

  const createFoodCart = () => {
    createFoodCartMutate({
      variables: {
        input: {
          customerName: fields?.name,
        },
      },
    });
  };
  const [updateFoodCartMutate, { data: editData, error: editError }] =
    useUpdateFoodCartMutation({
      onCompleted: (data) => {
        if (data.updateFoodCart) {
          setModalShow(false);
          common.notify("S", "Cart Name Updated");
          childRef.current.parentCall();
        }
      },
      onError: (error) => {
        common.notify("E", error.message);
      },
    });

  const editFoodCart = (id) => {
    updateFoodCartMutate({
      variables: {
        input: {
          customerName: fields?.name,
        },
        cartId: id,
      },
    });
  };

  //Delete Cart

  const [deleteFoodCartMutate, { data: deleteCart, error: errorCart }] =
    useDeleteFoodCartMutation({
      onCompleted: (data) => {
        if (data.deleteFoodCart) {
          childRef.current.parentCall();
          common.notify("S", "Cart Deleted");
        }
      },
      onError: (error) => {
        common.notify("E", error.message);
      },
    });

  const deleteFoodCart = (id) => {
    deleteFoodCartMutate({
      variables: {
        cartId: id,
      },
    });
  };
  // const [] = useCreateFoodCartMutation();

  // Api
  const createMenu = async () => {
    setloading(true);
    // api call
    let createMenuQuery = `mutation CreateFoodMenu($input: FoodMenuInput!) {
      createFoodMenu(input: $input) {
          id
          name
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: createMenuQuery,
        variables: {
          input: {
            name: fields.name,
            isActive: fields.isActive?.value,
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.createFoodMenu &&
        Object.keys(data?.data?.createFoodMenu).length > 0
      ) {
        childRef.current.parentCall();
        setMultiValue({ name: "" });
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const editMenu = async () => {
    setloading(true);
    // api call
    let editMenuQuery = `mutation UpdateFoodMenu($menuId: ID!, $input: FoodMenuInput!) {
      updateFoodMenu(menuId: $menuId, input: $input) {
          id
          name
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: editMenuQuery,
        variables: {
          menuId: menuId,
          input: {
            name: fields?.name,
            isActive: fields?.isActive?.value,
          },
        },
      },
    };

    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.updateFoodMenu &&
        Object.keys(data?.data?.updateFoodMenu).length > 0
      ) {
        childRef.current.parentCall();
        setModalShow(false);
      }
      setloading(false);
    });
  };

  // const deleteCart = async (menuId) => {
  //   setloading(true);
  //   // api call
  //   let deleteCartQuery = `mutation DeleteFoodCart($cartId: ID!) {
  //     deleteFoodCart(cartId: $cartId)
  // }`;

  //   let options = {
  //     url: "base_url",
  //     method: "POST",
  //     auth: "token",
  //     body: {
  //       query: deleteCartQuery,
  //       variables: {
  //         cartId: menuId,
  //       },
  //     },
  //   };
  //   api.call(options, (res) => {
  //     // return
  //     let data = res?.data;
  //     if (data?.data?.deleteFoodMenu) {
  //       childRef.current.parentCall();
  //     }
  //     setloading(false);
  //   });
  // };

  const {
    data: foodCart,
    error: foodCartErrors,
    refetch,
  } = useGetFoodCartQuery();

  useEffect(() => {
    if (menuId) {
      refetch({ cartId: menuId });
    }
  }, [menuId]);
  useEffect(() => {
    getItemList();
  }, [search]);
  const getCartById = (menuId) => {
    setViewLoading(true);
    let getCartQuery = `query FoodCart($cartId: ID!) {
      foodCart(cartId: $cartId) {
          id
          customerName
          subTotal
          taxTotal
          grandTotal
          currency
          items {
              id
              price
              quantity
              subTotal
              currency
              item {
                  id
                  name
                  description
                  image
                  basePrice
                  taxPercentage
                  isActive
                  existsInCart
              }
          }
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: getCartQuery,
        variables: {
          cartId: menuId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data?.data?.foodCart;
      setSelectedItem(data);
      setViewLoading(false);
    });
  };

  const onselect = (menu, name) => {
    setMultiValue({ [name]: menu });
  };

  const openModal = (val) => {
    resetForm();
    setModalShow(val);
  };

  const resetForm = () => {
    setMultiValue({
      name: "",
      isActive: "",
    });
    setMenuId(null);
    setloading(false);
  };

  //add update  mutation query
  const [
    AddFoodItemToCart,
    { data: dataItem, error: errorItem, loading: itemLoading },
  ] = useAddFoodItemToCartMutation({
    onCompleted: (data) => {
      if (data.addFoodItemToCart) {
        setItemPopup(false);
        common.notify("S", "Item Added to the Cart");
        childRef.current.parentCall();
      }
    },
    onError: (error) => {
      common.notify("E", error.message);
    },
    refetchQueries: [
      {
        query: GetFoodCartDocument,
        variables: {
          cartId: menuId,
        },
      },
      GetFoodItemsDocument,
    ],
  });

  const AddFoodItem = (id, qnty) => {
    if (menuId) {
      AddFoodItemToCart({
        variables: {
          cartId: menuId,
          // itemId: items?.value,
          itemId: id,
          input: {
            quantity: qnty ? qnty : 1,
          },
        },
      });
    }
  };
  //Edit Food Items
  const [
    UpdateFoodItemToCart,
    {
      data: updatedataItem,
      error: updateerrorItem,
      loading: updateitemLoading,
    },
  ] = useUpdateFoodCartItemMutation({
    onCompleted: (data) => {
      if (data.updateFoodCartItem) {
        common.notify("S", "Item Quantity updated");
        childRef.current.parentCall();
      }
    },
    onError: (error) => {
      common.notify("E", error.message);
    },
    refetchQueries: [
      {
        query: GetFoodCartDocument,
        variables: {
          cartId: menuId,
        },
      },
      GetFoodItemsDocument,
    ],
  });

  const EditFoodItem = (qnty, id) => {
    if (menuId) {
      UpdateFoodItemToCart({
        variables: {
          cartId: menuId,
          cartItemId: id,
          input: {
            quantity: qnty,
          },
        },
      });
    }
  };
  //Delete Food Items

  const [
    DeleteFoodItemCart,
    { data: deletedataItem, error: deleterrorItem, loading: deleteitemLoading },
  ] = useDeleteFoodCartItemMutation({
    onCompleted: (data) => {
      if (data.deleteFoodCartItem) {
        setModalShow(false);
        common.notify("S", "Item Deleted");
        childRef.current.parentCall();
      }
    },
    onError: (error) => {
      common.notify("E", error.message);
    },
  });

  const DeleteFoodItem = (itemId, id) => {
    if (menuId) {
      DeleteFoodItemCart({
        variables: {
          cartId: menuId,
          itemId: id,
          cartItemId: itemId,
        },
      });
    }
  };
  const updateCountIncrease = (qnty, id) => {
    const newQuantity = parseInt(qnty + 1);
    console.log("Quantity", parseInt(qnty + 1));
    setEditQuantity((prevQuantities) => ({
      ...prevQuantities,
      [id]: newQuantity,
    }));
    EditFoodItem(newQuantity, id);
  };
  const updateCountDecrease = (qnty, id) => {
    if (qnty > 1) {
      const newQuantity = parseInt(qnty - 1);
      console.log("Quantity", parseInt(qnty - 1));
      setEditQuantity((prevQuantities) => ({
        ...prevQuantities,
        [id]: newQuantity,
      }));
      EditFoodItem(newQuantity, id);
    }
  };
  // let filterCart = foodCart.filter((value) => value.id == id);
  const handleDecreaseQuantity = (itemId) => {
    if (quantities[itemId] > 1) {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [itemId]: prevQuantities[itemId] - 1,
      }));
    }
  };

  const handleIncreaseQuantity = (itemId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemId]: (prevQuantities[itemId] || 0) + 1,
    }));
  };
  const getItemList = () => {
    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: agModuleList["foodItems"].query,
        variables: {
          search: search,
          isActive: true,
          limit: 1000,
          offset: 0,
        },
      },
    };
    // call
    api.call(options, (res) => {
      if (res.status == 200) {
        let data = res?.data?.data?.foodItems?.list;
        let arr = [];
        data?.length > 0 &&
          data?.map((item) => {
            let option = {
              label: item.name,
              value: item.id,
            };
            arr.push(option);
            setQuantities((prevQuantities) => ({
              ...prevQuantities,
              [item.id]: 1,
            }));
          });
        setItemList(res?.data?.data?.foodItems?.list);
      }
    });
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };
  return (
    <>
      <div className="pages-wrapper">
        <div className="page-title-wrap">
          <div>
            <h5 className="page-title-txt">Cart</h5>
          </div>
          <div>
            <button className="add-btn" onClick={() => openModal(true)}>
              <img
                src={common.loadImg("plus-icn.svg")}
                alt="icn"
                width="16"
                height="16"
              />
              <span>Add Cart</span>
            </button>
          </div>
        </div>
        <ListTable ref={childRef} module="foodCarts" onAction={onAction} />
      </div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {menuId ? "Update Cart" : "Add Cart"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Customer Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter the name"
                  className="form-control form-input"
                  value={fields.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <p className="error-txt">{errors?.name}</p>
              </div>
              {itemsIncart?.items?.length > 0 && menuId && (
                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>Sno</th>

                        <th>Image</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Action</th>

                        {/* <th>Delete</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {itemsIncart?.items?.length > 0 &&
                        itemsIncart?.items?.map((opt, i) => (
                          <tr key={i}>
                            <td>{i + 1 + "."}</td>

                            <td>
                              <img
                                src={config.api.cloudImgPath + opt?.item?.image}
                                alt="Brand Logo"
                                width={50}
                                height={50}
                              />
                            </td>
                            <td>{opt?.item?.name}</td>

                            <td>
                              <button
                                className="inc-desc-btn me-3"
                                onClick={() =>
                                  updateCountDecrease(
                                    editQuantity[opt.id],
                                    opt.id
                                  )
                                }
                              >
                                -
                              </button>
                              <span>{editQuantity[opt.id]}</span>
                              <button
                                className="inc-desc-btn ms-3"
                                onClick={() =>
                                  updateCountIncrease(
                                    editQuantity[opt.id],
                                    opt.id
                                  )
                                }
                              >
                                +
                              </button>
                            </td>

                            <td>
                              <RiDeleteBinLine
                                size={25}
                                onClick={() => {
                                  DeleteFoodItem(opt.id, opt?.item?.id);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {/* <div className="col-12">
                <label className="form-label">Status</label>
                <Select
                  value={fields.isActive}
                  name="country"
                  options={statusOption}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  onChange={(data) => onselect(data, "isActive")}
                />
                <p className="error-txt">{errors.isActive}</p>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-foot-btn dark"
              // onClick={(e) => onSubmit(e)}
              onClick={(e) => onSave(e)}
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* //**ADD ITEMS IN CART */}

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={itemPopup}
        onHide={() => setItemPopup(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="w-100 text-center"
          >
            Add Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <form autoComplete="off">
              <div className="col-md-6 mx-auto search-input-wrap">
                <img
                  src={common.loadImg("search-icn.svg")}
                  alt="icon"
                  width="20"
                  height="20"
                />

                <input
                  type="text"
                  name="search"
                  id="search"
                  value={search}
                  placeholder="Search Items"
                  className="search-input"
                  onChange={handleSearch}
                  name="hidden"
                />
              </div>
            </form>
            <div className="row mt-3">
              <div className="col-12 text-center">
                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <Table responsive striped>
                    <thead>
                      <tr>
                        <th>Sno</th>

                        <th>Image</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Action</th>

                        {/* <th>Delete</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {itemList?.length > 0 &&
                        itemList?.map((opt, i) => (
                          <tr key={i}>
                            <td>{i + 1 + "."}</td>

                            <td>
                              <img
                                src={config.api.cloudImgPath + opt?.image}
                                alt="Brand Logo"
                                width={50}
                                height={50}
                              />
                            </td>
                            <td>{opt?.name}</td>

                            <td>
                              <button
                                className="inc-desc-btn me-3"
                                onClick={() => handleDecreaseQuantity(opt.id)}
                                disabled={filteredItems.includes(opt.id)}
                              >
                                -
                              </button>
                              <span>{quantities[opt.id] || 1}</span>
                              <button
                                className="inc-desc-btn ms-3"
                                onClick={() => handleIncreaseQuantity(opt.id)}
                                disabled={filteredItems.includes(opt.id)}
                              >
                                +
                              </button>
                            </td>

                            {filteredItems.includes(opt.id) ? (
                              <td>Exist In cart </td>
                            ) : (
                              <td>
                                {" "}
                                <button
                                  className="add-cart-btn"
                                  onClick={() => {
                                    AddFoodItem(opt.id, quantities[opt.id]);
                                  }}
                                >
                                  ADD
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
                {/* <Select
                  name="cuisine"
                  value={items}
                  options={itemList}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  // isMulti={true}
                  onChange={(data) => onselect(data)}
                /> */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setItemPopup(false)}
            >
              Close
            </button>
            {/* <button
              className="modal-foot-btn dark"
              onClick={() => AddFoodItem()}
            >
              Save
            </button> */}
          </div>
        </Modal.Footer>
      </Modal>

      {/* view */}
      {isView && (
        <ViewModal
          loading={viewLoading}
          show={isView}
          item={selectedItem}
          closeModal={() => setIsView(false)}
          module={"foodCart"}
          title={"Cart"}
        />
      )}
    </>
  );
}
