export const config = {
	public_url: process.env.REACT_APP_PUBLIC_URL,
	type: process.env.REACT_APP_ROLE || '',
	free_code: process.env.REACT_APP_FREE_CODE,
	// api
	api: {
		isExpired: 401,
		url: process.env.REACT_APP_API_URL,
		subscription_url: process.env.REACT_APP_SUBSCRIPTION_URL,
		socket_url: process.env.REACT_APP_SOCKET_URL,
		cloudImgPath: 'https://noveltycard2020.s3.amazonaws.com/',
	},
	// google
	mapApiKey: 'AIzaSyAygyBPl0BTS79YlRq8acAn3dOrD4sF4XA',
	// payment
	stripe: {
		publishableKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
	},
	// others
	tenantId: 33,
	userId: 5514, // 6325
	cryptKey: '#cErLyPpKt#',
	firebaseConfig: {
		apiKey: 'AIzaSyAygyBPl0BTS79YlRq8acAn3dOrD4sF4XA',
		authDomain: 'novelty-card.firebaseapp.com',
		databaseURL: 'https://novelty-card.firebaseio.com',
		projectId: 'novelty-card',
		storageBucket: 'novelty-card.appspot.com',
		messagingSenderId: '1016858398368',
		appId: '1:1016858398368:web:650cb890c9662ab1fc83c5',
		measurementId: 'G-WQNP9NP8GT',
	},
	// elements
	elements: {
		dp: {
			A: 'MMMM DD, YYYY',
			B: 'MMM DD, YYYY | hh:mm A',
			C: 'MMM DD, YYYY hh:mm A',
			D: 'MMM DD, YYYY | hh:mm:ss A',
		},
		singleDp: {
			singleDatePicker: true,
			showDropdowns: true,
			locale: {
				format: 'MMMM DD, YYYY',
			},
			autoApply: true,
			autoUpdateInput: false,
			isClearable: true,
		},
		toast: {
			position: 'top-right',
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		},
	},
};
