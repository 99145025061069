import React, { useEffect, useRef, useState } from "react";
import { common } from "../../helpers/common";
import ListTable from "../../components/listTable";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AmlHashForm from "aml-hash-form";
import { api } from "../../helpers/api";
import { config } from "../../helpers/config";
import ViewModal from "../../components/viewModal";
import { CropIt } from "../../components/cropIt";

const statusOption = [
  { value: true, label: "Active" },
  { value: false, label: "Deactive" },
];

export default function MenuHeadings(props) {
  const childRef = useRef();
  const [loading, setloading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [itemId, setItemId] = useState(null);
  const fileRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState({
    image: "",
  });
  const [imagePath, setImagePath] = useState("");

  const [menuOptions, setMenuOptions] = useState([]);
  const [cuisineOptions, setCuisineOptions] = useState([]);
  const [selectedStatus, setStatus] = useState();
  const [isView, setIsView] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  let imageCroper = React.useRef(null);

  const formFields = {
    name: {
      validate: ["req#Name is required"],
    },
    description: {
      validate: ["req#Description is required"],
    },
    menu: {
      validate: ["objValReq#Please select the menu"],
      obj: "value",
    },
    cuisine: {
      validate: ["objValReq#Please select the cuisine"],
      obj: "value",
    },
    basePrice: {
      validate: ["req#Base price is required"],
    },
    taxPercentage: {
      validate: ["req#Tax percentage is required"],
    },
    isActive: {
      validate: ["req#Status is required"],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // useEffect(() => {
  //   getMenus();
  //   getCuisines();
  // }, []);

  // handler
  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = handleSubmit();

    if (isValid) {
      if (itemId) {
        if (selectedImage) {
          const [imagePath] = await Promise.all([uploadImage()]);
          editMenuItem(imagePath);
        } else {
          editMenuItem();
        }
      } else {
        if (!selectedImage) {
          setError((prev) => ({ ...prev, image: "Please select a image" }));
        } else {
          const [imagePath] = await Promise.all([uploadImage()]);
          createMenuItem(imagePath);
        }
      }
    }
  };

  const uploadImage = () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    let data = {
      url: "upload",
      method: "POST",
      auth: "none",
      body: formData,
      cType: 6,
    };
    return new Promise((resolve, reject) => {
      api.call(data, (res) => {
        let response = res.data;
        if (res.status == 200) {
          let image = response?.data?.key;
          setImagePath(image);
          resolve(response?.data?.key);
          return image;
        } else {
          common.notify("E", "Error In Uploading documents ,please try again");
          reject("Error in uploading logo");
        }
      });
    });
  };

  const onAction = (data) => {
    setItemId(data.item.id);
    getItemById(data.item.id);
    if (data.type === "View") {
      setIsView(true);
    } else if (data.type === "Edit") {
      let menuArr = [];
      data?.item?.menus &&
        data?.item?.menus?.length > 0 &&
        data?.item?.menus?.map((item) => {
          let val = { label: item.name, value: item.id };
          menuArr.push(val);
        });

      let categoriesArr = [];
      data?.item?.categories &&
        data?.item?.categories?.length > 0 &&
        data?.item?.categories?.map((item) => {
          let val = { label: item.name, value: item.id };
          categoriesArr.push(val);
        });
      let status = statusOption.filter(
        (item) => item?.value == data?.item?.isActive
      );
      setMultiValue({
        name: data?.item?.name,
        description: data?.item?.description,
        basePrice: data?.item?.basePrice,
        taxPercentage: data?.item?.taxPercentage,
        cuisine: categoriesArr,
        menu: menuArr,
        isActive: status[0],
      });
      setImagePath(data?.item?.image);
      setModalShow(true);
    } else if (data.type === "Delete") {
      deleteMenu(data.item.id);
    }
  };

  const getItemById = (itemId) => {
    setViewLoading(true);
    let getItemQuery = `query FoodItem($itemId: ID!) {
      foodItem(itemId: $itemId) {
          id
          name
          description
          image
          basePrice
          taxPercentage
          isActive
          menus {
              id
              name
              isActive
          }
          categories {
            id
            name
            image
            isActive
        }
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: getItemQuery,
        variables: {
          itemId: itemId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data?.data?.foodItem;
      setSelectedItem(data);
      setViewLoading(false);
    });
  };

  // Api
  const createMenuItem = async (imagePath) => {
    setloading(true);
    // api call
    let createItemQuery = `mutation CreateFoodItem($input: FoodItemInput!) {
      createFoodItem(input: $input) {
        id
        name
        description
        image
        basePrice
        taxPercentage
        menus {
          id
          name
        }
        categories {
          id
          name
          image
          isActive
      }
      }
    }`;

    let menuArr = [];

    fields?.menu &&
      fields?.menu?.length > 0 &&
      fields?.menu?.map((item) => {
        menuArr.push(item.value);
      });

    let categoriesArr = [];

    fields?.cuisine &&
      fields?.cuisine?.length > 0 &&
      fields?.cuisine?.map((item) => {
        categoriesArr.push(item.value);
      });
    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: createItemQuery,
        variables: {
          input: {
            name: fields.name,
            description: fields.description,
            image: imagePath,
            basePrice: fields.basePrice,
            taxPercentage: fields.taxPercentage,
            menus: menuArr,
            categories: categoriesArr,
            isActive: fields.isActive?.value,
          },
        },
      },
    };

    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.createFoodItem &&
        Object.keys(data?.data?.createFoodItem).length > 0
      ) {
        childRef.current.parentCall();
        resetForm();
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const editMenuItem = async (path) => {
    setloading(true);
    // api call
    let editItemQuery = `mutation UpdateFoodItem($itemId: ID!, $input: FoodItemInput!) {
      updateFoodItem(itemId: $itemId, input: $input) {
          id
          name
          description
          image
          basePrice
          taxPercentage
          menus {
              id
              name
          }
          categories {
            id
            name
            image
            isActive
        }
      }
  }`;

    let menuArr = [];

    fields?.menu &&
      fields?.menu?.length > 0 &&
      fields?.menu?.map((item) => {
        menuArr.push(item.value);
      });

    let categoriesArr = [];

    fields?.cuisine &&
      fields?.cuisine?.length > 0 &&
      fields?.cuisine?.map((item) => {
        categoriesArr.push(item.value);
      });
    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: editItemQuery,
        variables: {
          itemId: itemId,
          input: {
            name: fields.name,
            description: fields.description,
            image: path ? path : imagePath,
            basePrice: fields.basePrice,
            taxPercentage: fields.taxPercentage,
            menus: menuArr,
            categories: categoriesArr,
            isActive: fields.isActive?.value,
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.updateFoodItem &&
        Object.keys(data?.data?.updateFoodItem).length > 0
      ) {
        childRef.current.parentCall();
        resetForm();
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const deleteMenu = async (itemId) => {
    setloading(true);
    // api call
    let deleteMenuQuery = `mutation DeleteFoodItem($itemId: ID!) {
      deleteFoodItem(itemId: $itemId)
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: deleteMenuQuery,
        variables: {
          itemId: itemId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.data?.deleteFoodItem) {
        childRef.current.parentCall();
      }
      setloading(false);
    });
  };

  const getMenus = () => {
    let menuQuery = `query FoodMenus($search:String,$limit:Int,$offset:Int){foodMenus(filters:{search:$search limit:$limit offset:$offset}){totalCount list{id name items{id name description image basePrice taxPercentage categories {
      id
      name
      image
      isActive
  }}}}}`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: menuQuery,
      },
    };
    api.call(options, (res) => {
      let data = res?.data?.data?.foodMenus?.list;
      let arr = [];
      data?.length > 0 &&
        data?.map((item) => {
          let option = {
            label: item.name,
            value: item.id,
          };
          arr.push(option);
        });
      setMenuOptions(arr);
    });
  };

  const getCuisines = () => {
    let cuisineQuery = `query FoodCategories($search: String, $isActive: Boolean, $limit: Int, $offset: Int) {
      foodCategories(filters: { search: $search, isActive: $isActive, limit: $limit, offset: $offset }) {
          list {
              id
              name
              image
              isActive
          }
          totalCount
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: cuisineQuery,
      },
    };
    api.call(options, (res) => {
      let data = res?.data?.data?.foodCategories?.list;
      let arr = [];
      data?.length > 0 &&
        data?.map((item) => {
          let option = {
            label: item.name,
            value: item.id,
          };
          arr.push(option);
        });
      setCuisineOptions(arr);
    });
  };

  const handleImageClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      let cropSize = {};
      cropSize = { width: 256, height: 256 };

      imageCroper.onFileChange(event, cropSize);
      setSelectedImage(file);
    } else {
      setSelectedImage(null);
    }
  };

  const cropImageComplete = (data, type) => {
    if (data.status == "success") {
      setSelectedImage(data.image);
      fileRef.current.value = "";
    } else {
      fileRef.current.value = "";
      setSelectedImage(null);
    }
  };

  const onselect = (menu, name) => {
    setMultiValue({ [name]: menu });
  };

  const openModal = (val) => {
    getMenus();
    getCuisines();
    resetForm();
    setModalShow(val);
  };

  const resetForm = () => {
    setMultiValue({
      name: "",
      description: "",
      basePrice: "",
      taxPercentage: "",
      cuisine: "",
      menu: "",
      isActive: "",
    });
    setImagePath("");
    setSelectedImage(null);
    setItemId(null);
    setloading(false);
  };

  return (
    <>
      <div className="pages-wrapper">
        <div className="page-title-wrap">
          <div>
            <h5 className="page-title-txt">Menu Items</h5>
          </div>
          <div>
            <button className="add-btn" onClick={() => openModal(true)}>
              <img
                src={common.loadImg("plus-icn.svg")}
                alt="icn"
                width="16"
                height="16"
              />
              <span>Add Item</span>
            </button>
          </div>
        </div>
        <ListTable ref={childRef} module="foodItems" onAction={onAction} />
      </div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {itemId ? "Update Item" : "Add Item"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Item Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter the name"
                  className="form-control form-input"
                  value={fields.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <p className="error-txt">{errors?.name}</p>
              </div>
              <div className="col-12">
                <label className="form-label">Item Image</label>
                <div className="brand-img-container" onClick={handleImageClick}>
                  {selectedImage && (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Brand Logo"
                      className="uploaded-logo"
                    />
                  )}
                  {imagePath && !selectedImage && (
                    <img
                      src={config.api.cloudImgPath + imagePath}
                      alt="Brand Logo"
                      className="uploaded-logo"
                    />
                  )}
                  {!selectedImage && !imagePath && (
                    <div className="click-load">Click to upload image</div>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileRef}
                  className="form-control form-input visually-hidden"
                  accept="image/*"
                  onChange={(e) => handleImage(e)}
                />
                <CropIt
                  ref={(instance) => {
                    imageCroper = instance;
                  }}
                  onComplete={(data) => cropImageComplete(data, "BannerImage")}
                  shape={"square"}
                />
                <p className="error-txt">{selectedImage ? "" : error?.image}</p>
              </div>

              <div className="col-12">
                <label className="form-label">Menu</label>
                <Select
                  name="menu"
                  value={fields.menu}
                  options={menuOptions}
                  className="react-select-container"
                  isMulti={true}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  onChange={(data) => onselect(data, "menu")}
                />
                <p className="error-txt">{errors.menu}</p>
              </div>

              <div className="col-12">
                <label className="form-label">Categories</label>
                <Select
                  name="cuisine"
                  value={fields.cuisine}
                  options={cuisineOptions}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  isMulti={true}
                  onChange={(data) => onselect(data, "cuisine")}
                />
                <p className="error-txt">{errors.cuisine}</p>
              </div>

              <div className="col-md-12">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  name="description"
                  className="form-control form-input"
                  value={fields.description}
                  onChange={handleChange}
                />
                <p className="error-txt">{errors.description}</p>
              </div>

              <div className="col-md-12">
                <label className="form-label">Base Price</label>
                <input
                  type="text"
                  name="basePrice"
                  className="form-control form-input"
                  value={fields.basePrice}
                  onChange={handleChange}
                />
                <p className="error-txt">{errors.basePrice}</p>
              </div>

              <div className="col-md-12">
                <label className="form-label">Tax Percentage</label>
                <input
                  type="text"
                  name="taxPercentage"
                  className="form-control form-input"
                  value={fields.taxPercentage}
                  onChange={handleChange}
                />
                <p className="error-txt">{errors.taxPercentage}</p>
              </div>

              <div className="col-12">
                <label className="form-label">Status</label>
                <Select
                  value={fields.isActive}
                  name="country"
                  options={statusOption}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  onChange={(data) => onselect(data, "isActive")}
                />
                <p className="error-txt">{errors.isActive}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-foot-btn dark"
              onClick={(e) => onSubmit(e)}
              disabled={loading}
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* view */}
      {isView && (
        <ViewModal
          loading={viewLoading}
          show={isView}
          item={selectedItem}
          closeModal={() => setIsView(false)}
          module={"foodItems"}
          title={"Menu Items"}
        />
      )}
    </>
  );
}
