import React from "react";
import { common } from "../helpers/common";

export default function StatusBar(props) {
  return (
    <>
      {!props.status && (
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="col-md-12 d-flex justify-content-center align-items-center"
              // style={{ height: "100vh" }}
            >
              <img
                src={common.loadImg("edutech-loader.gif")}
                className="loader-img"
                alt="application-icn"
                height={props.height}
                width={props.width}
              />
            </div>
          </div>
        </div>
      )}
      {props.status == "nodata" && (
        <div className="container">
          <div
            className="col-md-12  d-flex justify-content-center align-items-center "
            // style={{ height: "100vh" }}
          >
            <div className="">
              <img
                src={common.loadImg("no-data-found.png")}
                className="loader-img"
                alt="application-icn"
                height={props.height}
                width={props.width}
              />
              <h3 className="nodata-found-txt text-center">No Data Found!</h3>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
