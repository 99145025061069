import { common } from "./common";
import { config } from "./config";
// const
let refreshCall = false;
const base_url = config.api.url + "/graphql/query";

// services
const services = {
  // auth
  base_url: config.api.url + "/graphql/query",
  upload: config.api.url + "/rest/v1/upload",
};

// api call
export const api = {
  async call(data = {}, response, final) {
    getData(data);
    fetch(data.url, getOptions(data))
      .then(async (response) => {
        //refresh token
        if (response.status === 401) {
          let refreshTokenResult = await refreshAccessToken();
          console.log("=================refreshTokenResult", refreshTokenResult);
         if (refreshTokenResult?.data?.refreshTokens) {
            // let _data = refreshTokenResult.json();
            // common.localSet(
            //   "authData",
            //   common.crypt(refreshTokenResult?.data?.refreshTokens, true)
            // );
            const newAccessToken =
              refreshTokenResult?.data?.refreshTokens?.accessToken;
            const options = getOptions(data);
            options.headers.Authorization = `Bearer ${newAccessToken}`;
            return fetch(data.url, options).then(async (resp) => {
              return resp.text().then((res) => ({
                status: resp.status,
                data: res,
              }));
            });
          } else {
            localStorage.clear();
            common.redirect("login");
            return {
              status: 401,
            };
          }
        } else {
          if (data?.responseType == "file") {
            return response.blob();
          } else {
            return response.text().then((res) => ({
              status: response.status,
              data: res,
            }));
          }
        }
      })
      .then((result) => {
        if (data?.responseType == "file") {
          const url = URL.createObjectURL(result);
          const a = document.createElement("a");
          let fileName = data?.fileName ?? "pickauni";
          a.style.display = "none";
          a.href = url;
          a.download = `${fileName}`;
          // a.download = `${fileName}_${moment().format("YYYY_MM_DD_HH:mm")}.${
          //   data?.extensions ?? "pdf"
          // }`; // replace with your desired filename and extension
          document.body.appendChild(a);
          a.click();
          response(result);
        } else {
          if (validateData(result)) {
            try {
              if (result.status == 204) {
                result.data = {};
              } else {
                result.data = JSON.parse(result.data);
              }
            } catch (e) {
              result.data = {
                error: e.toString(),
              };
            }
            notify(data, result);
            response(result);
          }
        }
      })
      .catch((err) => {
        let result = {
          status: 404,
          data: {
            error: err.toString(),
          },
        };
        notify(data, result);
        response(result);
      })
      .finally(() => {
        if (typeof final !== "undefined") {
          final();
        }
      });
  }
};

// support
const getData = (data) => {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.auth = data.auth || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.cType = data.cType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.keyQuery = data.keyQuery || "";
    data.notify = data.notify ?? true;
    // set url
    if (data.type == "third") {
      data.url = data.url + data.query;
    } else {
      data.url = services[data.url] + data.query;
      if (data.keyQuery != "") {
        for (var key in data.keyQuery) {
          data.url = data.url.replace(`:${key}`, data.keyQuery[key]);
        }
      }
    }
    // set body
    if (data.body) {
      if (data.cType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.cType == 2) {
        let bodyParam = [];
        for (var property in data.body) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
};

function getOptions(data) {
  let reqOptions = {
    method: data.method,
    headers: getHeaders(data),
  };

  if (data.body) {
    reqOptions.body = data.body;
  }
  return reqOptions;
}

function getHeaders(data) {
  // default
  let headers = {};

  // content types
  let contentTypes = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
    6: "multipart/form-data",
  };
  if (data.cType !== 6) {
    headers["Content-Type"] = contentTypes[data.cType];
  }

  // extra content types
  let moreHeaderList = {
    rfv: "REST-Framework-Version",
  };
  if (data.moreHead) {
    for (var item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item];
    }
  }

  // authentication
  if (data.auth == "token") {
    let authData = common.getAuth();
    headers.Authorization = "Bearer " + authData?.accessToken; //.token;
  } else if (data.auth == "temp") {
    let tempData = common.tempDataGet();
    headers.Authorization = "Bearer " + tempData?.accessToken;
  } else if (data.auth == "basic") {
    headers.Authorization =
      "Basic " + btoa(data.credentials.param1 + ":" + data.credentials.param2);
  }

  return headers;
}

const validateData = (result) => {
  if (config.api.isExpired != "" && result.status == config.api.isExpired) {
    localStorage.clear();
    common.redirect();
    return false;
  } else {
    return true;
  }
};

const notify = (data, result) => {
  if (data.notify) {
    if (![200, 500, 400, 401].includes(result.status)) {
      common.notify("E", result.data.error);
    }
  }
};

// refreshToken
const refreshAccessToken = async () => {
  try {
    let authData = common.getAuth();
    let refreshTokenQuery = `mutation RefreshTokens($refreshToken: String!) {
      refreshTokens(input: { refreshToken: $refreshToken }) {
          accessToken
          refreshToken
      }
  }`;
  console.log("=============authData", authData);
    const response = await fetch(services["base_url"], {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: refreshTokenQuery,
        variables: {
          refreshToken: authData?.refreshToken,
        },
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        if (result?.data?.refreshTokens) {
          common.localSet(
            "authData",
            common.crypt(result?.data?.refreshTokens, true)
          );
        }
        return result;
      });
    return response;
  } catch (error) {}
};
