import React, { useState, useEffect } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap/";
import moment from "moment";
// includes
import { common } from "../../helpers/common";
import { api } from "../../helpers/api";
import { config } from "../../helpers/config";
import Modal from "react-bootstrap/Modal";
import Step4 from "./step4";

export default function Step8() {
  const [user, setUser] = useState({});
  const [logo, setLogo] = useState("");
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [licenseFile, setLicenseFile] = useState("");
  const [fssaiFile, setFssaiFile] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [editModal, setEditModal] = useState(false);

  let profileData = common.localGet("profileData");
  let profile = JSON.parse(profileData);
  //   let logo = common.localGet("logoFileBase64");
  //   let banner = common.localGet("bannerFileBase64");
  //   let license = common.localGet("licenseFileBase64");
  //   let fssai = common.localGet("fssaiFileBase64");
  //   let document = common.localGet("documentsFileBase64");
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  useEffect(() => {
    getCurrentUser();
    // if (license && fssai && document) {
    //   //   uploadLicense(license);
    //   //   uploadFssai(fssai);
    //   //   uploadDocument(document);
    // }
  }, []);

  //   const uploadLicense = (license) => {
  //     const splitData = license.split(",");

  //     const contentType = splitData[0].split(":")[1].split(";")[0];
  //     const base64String = splitData[1];
  //     //Convert Base64 to Blob

  //     const byteCharacters = atob(base64String);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: contentType });
  //     const formData = new FormData();

  //     formData.append("file", blob);

  //     let data = {
  //       url: "upload",
  //       method: "POST",
  //       auth: "token",
  //       body: formData,
  //       cType: 6,
  //     };

  //     api.call(data, (res) => {
  //       let response = res.data;
  //       setLicenseFile(response?.data?.key);
  //     });
  //   };
  //   const uploadFssai = (fssai) => {
  //     const splitData = fssai.split(",");
  //     const contentType = splitData[0].split(":")[1].split(";")[0];
  //     const base64String = splitData[1];
  //     //Convert Base64 to Blob
  //     const byteCharacters = atob(base64String);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: contentType });
  //     const formData = new FormData();

  //     formData.append("file", blob);

  //     let data = {
  //       url: "upload",
  //       method: "POST",
  //       auth: "token",
  //       body: formData,
  //       cType: 6,
  //     };

  //     api.call(data, (res) => {
  //       let response = res.data;
  //       setFssaiFile(response?.data?.key);
  //     });
  //   };
  //   const uploadDocument = (document) => {
  //     const splitData = document.split(",");
  //     const contentType = splitData[0].split(":")[1].split(";")[0];
  //     const base64String = splitData[1];
  //     //Convert Base64 to Blob
  //     const byteCharacters = atob(base64String);
  //     const byteNumbers = new Array(byteCharacters.length);
  //     for (let i = 0; i < byteCharacters.length; i++) {
  //       byteNumbers[i] = byteCharacters.charCodeAt(i);
  //     }
  //     const byteArray = new Uint8Array(byteNumbers);
  //     const blob = new Blob([byteArray], { type: contentType });
  //     const formData = new FormData();

  //     formData.append("file", blob);

  //     let data = {
  //       url: "upload",
  //       method: "POST",
  //       auth: "token",
  //       body: formData,
  //       cType: 6,
  //     };

  //     api.call(data, (res) => {
  //       let response = res.data;
  //       setDocumentFile(response?.data?.key);
  //     });
  //   };

  const getCurrentUser = async () => {
    // api call
    // let userQuery = `query GetCurrentUser {
    //   currentUser {
    //     __typename
    //     id
    //     name
    //     email
    //     avatar
    //     socialAvatar
    //     userType
    //     ... on FoodMerchantUser {
    //       profile {
    //         __typename
    //         id
    //          name
    //          phone
    //          image
    //          bannerImage
    //          websiteUrl
    //         description
    //         orderTypes
    //         country
    //         addressLine1
    //         addressLine2
    //         city
    //         state
    //         postalCode
    //         location {
    //           lat
    //           lng
    //         }
    //         businessRegistrationNumber
    //         businessDocuments
    //         vatId
    //         orderTypes
    //         operatingDays {
    //           id
    //           weekDay
    //           weekDayName
    //           isOpen
    //           openingTime
    //           closingTime
    //         }
    //         stripeAccountStatus
    //       }
    //     }
    //   }
    // }`;
    let userQuery = `query CurrentUser {
      currentUser {
          id
          name
          email
          avatar
          socialAvatar
          userType
          profile {
              __typename
              ... on FoodMerchantPrivate {
                  id
                  name
                  description
                  image
                  bannerImage
                  phone
                  email
                  country
                  addressLine1
                  addressLine2
                  city
                  state
                  postalCode
                  businessRegistrationNumber
                  businessDocuments
                  vatId
                  orderTypes
                  websiteUrl
                  stripeAccountStatus
                  isActive
                  location {
                      lat
                      lng
                  }
                  operatingDays {
                      id
                      weekDay
                      weekDayName
                      isOpen
                      openingTime
                      closingTime
                  }
              }
          }
      }
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: userQuery,
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;

      setUser(data?.data?.currentUser);

      const currentDay = new Date().getDay();
      const operatingDay = data?.data?.currentUser?.profile?.operatingDays.find(
        (day) => day.weekDay === currentDay
      );

      setOpenTime(operatingDay?.openingTime);
      setCloseTime(operatingDay?.closingTime);
      setLogo(user?.profile?.image);
    });
  };

  //   const profileSubmit = (e) => {
  //     e.preventDefault();
  //     // api call
  //     let foodQuery = `mutation CreateFoodMerchantProfile($input: FoodMerchantProfileInput!) {
  //       createFoodMerchantProfile(input: $input)
  //     }`;

  //     let options = {
  //       url: "base_url",
  //       method: "POST",
  //       auth: "token",
  //       body: {
  //         query: foodQuery,
  //         variables: {
  //           input: {
  //             name: profile.banner_name,
  //             description: profile.description,
  //             country: profile.country.value,
  //             addressLine1: profile.address,
  //             addressLine2: profile.address,
  //             city: profile.city,
  //             state: profile.state,
  //             postalCode: profile.postalCode,
  //             phone: profile.phone,
  //             email: user.email,
  //             location: { lat: 30, lng: 30 },
  //             /**8 digit num */
  //             businessRegistrationNumber: profile.businessRegistrationNumber,
  //             businessDocuments: [licenseFile, fssaiFile, documentFile],
  //             image: profile.logoPath,
  //             bannerImage: profile.bannerPath,
  //             orderTypes: profile.shop,
  //             operatingDays: [
  //               {
  //                 weekDay: 0,
  //                 isOpen: true,
  //                 openingTime: "10:00",
  //                 closingTime: "22:00",
  //               },
  //               {
  //                 weekDay: 1,
  //                 isOpen: true,
  //                 openingTime: "10:00",
  //                 closingTime: "22:00",
  //               },
  //               {
  //                 weekDay: 2,
  //                 isOpen: true,
  //                 openingTime: "10:00",
  //                 closingTime: "22:00",
  //               },
  //               {
  //                 weekDay: 3,
  //                 isOpen: true,
  //                 openingTime: "10:00",
  //                 closingTime: "22:00",
  //               },
  //               {
  //                 weekDay: 4,
  //                 isOpen: true,
  //                 openingTime: "10:00",
  //                 closingTime: "22:00",
  //               },
  //               {
  //                 weekDay: 5,
  //                 isOpen: true,
  //                 openingTime: "10:00",
  //                 closingTime: "22:00",
  //               },
  //               {
  //                 weekDay: 6,
  //                 isOpen: true,
  //                 openingTime: "10:00",
  //                 closingTime: "22:00",
  //               },
  //             ],
  //           },
  //         },
  //       },
  //     };
  //     api.call(options, (res) => {
  //       // return
  //       let data = res?.data;

  //       if (data?.errors) {
  //         data?.errors?.length > 0 &&
  //           data?.errors?.map((item) => {
  //             if (item?.extensions?.code === "UNEXPECTED_ERROR") {
  //               common.notify("E", "Something went wrong");
  //             }
  //           });
  //       } else {
  //         common.notify("S", "Profile submitted successfully");
  //       }
  //     });
  //   };
  return (
    <>
      <div className="container">
        <div className="row profile-bg">
          <div className="col-md-8 mx-auto mt-4">
            <div className="profile-steps-card">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="profile-wrap">
                  <Col sm={3} className="profile-left">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Profile</Nav.Link>
                      </Nav.Item>
                      {user?.userType != "ADMINISTRATOR" && (
                        <Nav.Item>
                          <Nav.Link eventKey="second">Brand Info</Nav.Link>
                        </Nav.Item>
                      )}
                      {user?.userType != "ADMINISTRATOR" && (
                        <Nav.Item>
                          <Nav.Link eventKey="third">Document</Nav.Link>
                        </Nav.Item>
                      )}
                    </Nav>
                  </Col>
                  <Col sm={9} className="profile-right">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="branch-header">
                          <h5 className="title">Profile Summary</h5>
                          {/* {user?.userType != "ADMINISTRATOR" && (
                            <button
                              className="edit-btn"
                              onClick={() => setEditModal(true)}
                            >
                              Edit
                            </button>
                          )} */}
                        </div>
                        <div className="personal-detail-wrap">
                          <p className="heading6">Personal Details</p>
                          <div className="row">
                            <div className="col-md-6">
                              <label className="label">Name</label>
                              <p className="data">{user?.name}</p>
                            </div>
                            <div className="col-md-6">
                              <label className="label">Email Address</label>
                              <p className="data">{user?.email}</p>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        {" "}
                        <div className="other-detail-wrap">
                          <div className="d-flex justify-content-between align-items-center branch-header">
                            <p className="heading6">Brand Info</p>
                            {user?.userType != "ADMINISTRATOR" && (
                              <button
                                className="edit-btn"
                                onClick={() => setEditModal(true)}
                              >
                                Edit
                              </button>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <p className="label">Image/Logo</p>
                              {/* <img
                              src={common.loadImg("branch-logo.png")}
                              alt="logo"
                              width="80"
                              height="80"
                            /> */}
                              <img
                                src={
                                  config.api.cloudImgPath + user?.profile?.image
                                }
                                alt="logo"
                                width="80"
                                height="80"
                              />
                            </div>
                            <div className="col-md-6 mb-4">
                              <p className="label">Image/Banner</p>
                              <img
                                src={
                                  config.api.cloudImgPath +
                                  user?.profile?.bannerImage
                                }
                                alt="banner"
                                className="w-100"
                              />
                            </div>
                            <div className="col-md-12">
                              <p className="label">Name</p>
                              <p className="data">{user?.profile?.name}</p>
                            </div>
                            <div className="col-md-12">
                              <p className="label">Description</p>
                              <p className="data">
                                {user?.profile?.description}
                              </p>
                            </div>
                            {user?.profile?.operatingDays?.map((day, i) => (
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="col-md-4">
                                  <p className="label">Day</p>
                                  <p className="data">{day.weekDayName}</p>
                                </div>
                                <div className="col-md-4">
                                  <p className="label">Opening Time</p>
                                  <p className="data">
                                    {day.isOpen == false
                                      ? "Closed"
                                      : moment(day.openingTime, "HH:mm").format(
                                          "hh:mm A"
                                        )}
                                  </p>
                                </div>
                                <div className="col-md-4">
                                  <p className="label">Closing Time</p>
                                  <p className="data">
                                    <p className="data">
                                      {day.isOpen == false
                                        ? "Closed"
                                        : moment(
                                            day.closingTime,
                                            "HH:mm"
                                          ).format("hh:mm A")}
                                    </p>
                                  </p>
                                </div>
                              </div>
                            ))}
                            {/* <div className="col-md-6">
                              <p className="label">Opening Time</p>
                              <p className="data">
                                {moment(openTime, "HH:mm").format("h:mm A")}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p className="label">Closing Time</p>
                              <p className="data">
                                {moment(closeTime, "HH:mm").format("h:mm A")}
                              </p>
                            </div> */}
                            <div className="col-md-12">
                              <p className="label">Phone Number</p>
                              <p className="data">{user?.profile?.phone}</p>
                            </div>
                            <div className="col-md-12">
                              <p className="label">Address</p>
                              <p className="data">
                                {user?.profile?.addressLine1 +
                                  "," +
                                  user?.profile?.addressLine2}
                              </p>
                            </div>
                            <div className="col-md-12">
                              <p className="label">Country</p>
                              <p className="data">{user?.profile?.country}</p>
                            </div>
                            <div className="col-md-6">
                              <p className="label">State</p>
                              <p className="data">{user?.profile?.state}</p>
                            </div>
                            <div className="col-md-6">
                              <p className="label">City</p>
                              <p className="data">{user?.profile?.city}</p>
                            </div>
                            <div className="col-md-12">
                              <p className="label">Website</p>
                              <p className="data">
                                {user?.profile?.websiteUrl}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        {" "}
                        <div className="other-detail-wrap mb-3">
                          <div className="d-flex justify-content-between align-items-center branch-header">
                            <p className="heading6">Documents</p>
                            {user?.userType != "ADMINISTRATOR" && (
                              <button
                                className="edit-btn"
                                onClick={() => setEditModal(true)}
                              >
                                Edit
                              </button>
                            )}
                          </div>

                          <div className="row">
                            {/* <div className="col-md-6">
                            <p className="label">Restaurant License</p>
                            <p className="data">{profile.license}</p>
                          </div>
                          <div className="col-md-6">
                            <p className="label">FSSAI License</p>
                            <p className="data">{profile.fssai}</p>
                          </div> */}
                            <div className="col-md-12">
                              <p className="label">
                                Document of registration establishment
                              </p>
                              {user?.profile?.businessDocuments.map(
                                (item, i) => (
                                  <p className="data" key={i}>
                                    {item}
                                  </p>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      {/* <div className="profile-footer">
                      <div className="row">
                        <div className="col-md-6">
                          <button className="profile-btn back">Back</button>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="profile-btn submit"
                            // onClick={(e) => profileSubmit(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div> */}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={editModal}
        onHide={() => setEditModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            EDIT PROFILE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Step4
            edit={editModal}
            close={() => setEditModal(false)}
            user={user}
            openTime={openTime}
            closeTime={closeTime}
            update={() => getCurrentUser()}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              // onClick={() => props.closeModal()}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
