import React from "react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import { api } from "../../helpers/api";
import { common } from "../../helpers/common";
import { IoFastFoodOutline } from "react-icons/io5";
import { MdShoppingBasket } from "react-icons/md";
import { MdOutlineHandshake } from "react-icons/md";

export default function SignUp() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({ email: "", userType: "" });
  const [selected, setSelected] = useState(null);
  // form
  const formFields = {
    firstName: {
      validate: [
        "req#Name is required",
        "firstName#Please enter the Name",
        "min:3",
        "max:25",
      ],
    },
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Characters",
        "max:15#Password should be maximum 15 Characters",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password do not match",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);
  const onSubmit = (e) => {
    e.preventDefault();

    let isValid = handleSubmit(e);
    if (isValid) {
      userRegister();
    }
    // if (isValid && selected != null) {
    //   userRegister();
    // }
    // if (selected == null) {
    //   setError((prev) => ({ ...prev, userType: "Please select one category" }));
    // }
  };

  // Api
  const userRegister = async () => {
    setloading(true);
    // api call
    let regQuery = `mutation InitiateRegistration($name: String!, $email: String!, $password: String!, $userType: UserType!) {
      initiateRegistration(
        input: {name: $name, email: $email, password: $password, userType: $userType}
      )
    }`;
    let options = {
      url: "base_url",
      method: "POST",
      auth: "none",
      body: {
        query: regQuery,
        variables: {
          name: fields.firstName,
          email: fields.email,
          password: fields.password,
          // userType: selected,
          userType: "MERCHANT",
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.errors) {
        data?.errors?.length > 0 &&
          data?.errors?.map((item) => {
            if (item?.extensions?.inputErrors?.email === "DUPLICATE") {
              setError((prev) => ({ ...prev, email: "Email already exists" }));
            }
          });
      } else {
        common.notify("S", "Otp has been send to" + " " + fields.email);
        navigate("/otpverification", { replace: true });
        let signupDetails = {
          name: fields.firstName,
          email: fields.email,
          password: fields.password,
          // userType: selected,
          userType: "MERCHANT",
        };

        common.tempDataSet(signupDetails);
      }
      setloading(false);
    });
  };

  return (
    <div className="auth-content-wrap signup-content-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div className="auth-form-card">
              <h3 className="title text-center">Create your account</h3>
              {/* 
              <div className="d-flex category-selection">
                <div
                  className={`col-md-4 ${
                    selected == "FOOD_MERCHANT" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="food"
                    value="FOOD_MERCHANT"
                    name="businessCategory"
                    className="category-business"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    htmlFor="food"
                    className={
                      selected == "FOOD_MERCHANT" ? " category-bg-active" : ""
                    }
                  >
                    <div
                      className={
                        selected == "FOOD_MERCHANT"
                          ? "square-box active "
                          : "square-box"
                      }
                    >
                      <span
                        className={
                          selected == "FOOD_MERCHANT"
                            ? "heading-active px-3"
                            : "heading px-3"
                        }
                      >
                        Food
                      </span>
                      <IoFastFoodOutline
                        color={selected == "FOOD_MERCHANT" ? "white" : "#cccc"}
                        size={32}
                      />
                    </div>
                  </label>
                </div>
                <div
                  className={`col-md-4 ${
                    selected == "SHOP_MERCHANT" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="shop"
                    value="SHOP_MERCHANT"
                    name="businessCategory"
                    className="category-business"
                    // onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    htmlFor="shop"
                    className={
                      selected == "SHOP_MERCHANT" ? "category-bg-active" : ""
                    }
                  >
                    <div
                      className={
                        selected == "SHOP_MERCHANT"
                          ? "square-box active "
                          : "square-box"
                      }
                    >
                      <span
                        className={
                          selected == "SHOP_MERCHANT"
                            ? "heading-active px-3"
                            : "heading px-3"
                        }
                      >
                        Shop
                      </span>
                      <MdShoppingBasket
                        color={selected == "SHOP_MERCHANT" ? "white" : "#cccc"}
                        size={32}
                      />
                    </div>
                  </label>
                </div>
                <div
                  className={`col-md-4 ${
                    selected == "SERVICE_MERCHANT" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="service"
                    value="SERVICE_MERCHANT"
                    name="businessCategory"
                    className="category-business"
                    // onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    htmlFor="service"
                    className={
                      selected == "SERVICE_MERCHANT" ? "category-bg-active" : ""
                    }
                  >
                    <div
                      className={
                        selected == "SERVICE_MERCHANT"
                          ? " square-box active "
                          : " square-box"
                      }
                    >
                      <span
                        className={
                          selected == "SERVICE_MERCHANT"
                            ? "heading-active px-2"
                            : "heading px-2"
                        }
                      >
                        Service
                      </span>
                      <MdOutlineHandshake
                        color={
                          selected == "SERVICE_MERCHANT" ? "white" : "#cccc"
                        }
                        size={32}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <p className="error-txt">
                {selected == null ? (error.userType ? error.userType : "") : ""}
              </p> */}
              <label className="label">Name</label>
              <input
                type="text"
                name="firstName"
                value={fields.firstName}
                className="form-control input"
                placeholder="Enter the firstname"
                onChange={handleChange}
              />
              <p className="error-txt">{errors.firstName}</p>

              <label className="label">Email Address</label>
              <input
                type="email"
                name="email"
                value={fields.email}
                className="form-control input"
                placeholder="Enter the email address"
                onChange={handleChange}
              />
              <p className="error-txt">
                {error.email ? error.email : errors.email}
              </p>
              <label className="label">Password</label>
              <input
                type="password"
                name="password"
                value={fields.password}
                className="form-control input"
                placeholder="Password"
                onChange={handleChange}
              />
              <p className="error-txt">{errors.password}</p>
              <label className="label">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={fields.confirmPassword}
                className="form-control input"
                placeholder="Password"
                onChange={handleChange}
              />
              <p className="error-txt">{errors.confirmPassword}</p>
              {/* <p className="d-flex justify-content-end mb-0">
                <a href="#" className="plain-link">
                  Forgot Password?
                </a>
              </p> */}
              <button className="auth-btn w-100" onClick={(e) => onSubmit(e)}>
                {loading ? "Processing" : "Create Account"}
              </button>
              <p className="nav-login text-center mt-3">
                Already have an account?{" "}
                <Link to="/login" className="nav-login ">
                  Click here to login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
