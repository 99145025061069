import React, { useRef, useState } from "react";
import ListTable from "../components/listTable";
import { api } from "../helpers/api";
import ViewModal from "../components/viewModal";
import AmlHashForm from "aml-hash-form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { common } from "../helpers/common";

const statusOption = [
  { value: true, label: "Active" },
  { value: false, label: "Deactive" },
];

export default function Payment(props) {
  const childRef = useRef();
  const [merchantId, setMerchantId] = useState(null);
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [isView, setIsView] = useState(false);
  const [loading, setloading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [statusOptions, setStatusOptions] = useState(statusOption);

  const formFields = {
    isActive: {
      validate: ["req#Status is required"],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  const onAction = (data) => {
    setMerchantId(data.item.id);
    getPaymentById(data.item.id);
    if (data.type === "View") {
      setIsView(true);
    } else if (data.type === "Edit") {
      setModalShow(true);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      setloading(true);
      if (merchantId) {
        editMerchant();
      }
    }
  };

  const getPaymentById = (id) => {
    setViewLoading(true);
    let getMerchantQuery = `query Payment($paymentId: ID!) {
      payment(paymentId: $paymentId) {
          id
          ordermerchantType
          amount
          currency
          paymentStatus
          paidAt
          user {
              id
              name
              email
              avatar
              socialAvatar
              userType
          }
          order {
              id
              ... on FoodOrder {
                  id
                  type
                  status
                  subTotal
                  taxTotal
                  grandTotal
                  currency
                  customerNotes
                  allowedStatusUpdates
                  orderedAt
                  items {
                      id
                      price
                      quantity
                      item {
                          id
                          name
                          description
                          image
                          basePrice
                          taxPercentage
                          isActive
                          existsInCart
                      }
                  }
              }
          }
          ... on StripePayment {
              paymentMethodType
              paymentMethodLast4
              paymentMethodBrand
              stripeRefundId
              refundAmount
              refundStatus
              refundedAt
          }
          ... on WalletPayment {
              walletRefundId
              refundAmount
              refundStatus
              refundedAt
          }
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: getMerchantQuery,
        variables: {
          paymentId: id,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data?.data?.payment;
      statusOptions?.length > 0 &&
        statusOptions?.map((item) => {
          if (item.value === data?.isActive) {
            setMultiValue({ isActive: item });
          }
        });
      setSelectedMerchant(data);
      setViewLoading(false);
    });
  };

  const editMerchant = async () => {
    setloading(true);
    // api call
    let editOrderQuery = `mutation UpdateFoodmerchantActiveStatus($merchantId: ID!, $isActive: Boolean!) {
      updateFoodmerchantActiveStatus(merchantId: $merchantId, isActive: $isActive)
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: editOrderQuery,
        variables: {
          merchantId: merchantId,
          isActive: fields.isActive.value,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.data?.updateFoodmerchantActiveStatus) {
        childRef.current.parentCall();
        resetForm();
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const resetForm = () => {
    setMultiValue({
      isActive: "",
    });
    setMerchantId(null);
    setloading(false);
  };

  const onselect = (val, name) => {
    setMultiValue({ [name]: val });
  };

  return (
    <>
      <div className="pages-wrapper">
        <div className="page-title-wrap">
          <div>
            <h5 className="page-title-txt">Payments</h5>
          </div>
        </div>
        <ListTable ref={childRef} module="payments" onAction={onAction} />
      </div>

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {"Update Merchant"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Status</label>
                <Select
                  value={fields.isActive}
                  name="country"
                  options={statusOptions}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  onChange={(data) => onselect(data, "isActive")}
                />
                <p className="error-txt">{errors.isActive}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-foot-btn dark"
              onClick={(e) => onSubmit(e)}
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <ViewModal
        loading={viewLoading}
        show={isView}
        item={selectedMerchant}
        closeModal={() => setIsView(false)}
        module={"payments"}
        title={"Payments"}
      />
    </>
  );
}
