import React, { useState } from "react";
// includes
import { common } from "../../helpers/common";
import { Link, useNavigate } from "react-router-dom";
import { IoFastFoodOutline } from "react-icons/io5";
import { MdShoppingBasket } from "react-icons/md";
import { MdOutlineHandshake } from "react-icons/md";

export default function Step1(props) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState({ userType: "" });
  const handleContinue = () => {
    if (selected != null) {
      common.localSet("profileData", { business: selected });
      props.onSuccess();
    } else {
      common.notify("E", "Please Select a Business");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <div className="profile-steps-card">
            <div className="card-header pb-0">
              <a href="" className="back-btn-wrap">
                <img src={common.loadImg("back-arrow.svg")} alt="back-arrow" />
                <span>Back</span>
              </a>
              <div className="title-wrap">
                <h3 className="title">Choose your business</h3>
                <p className="desc">You can select only one</p>
              </div>
            </div>
            <div className="card-body business-category-wrap">
              <div className="d-flex category-selection">
                <div
                  className={`col-md-4 ${
                    selected == "FOOD_MERCHANT" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="food"
                    value="FOOD_MERCHANT"
                    name="businessCategory"
                    className="category-business"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    htmlFor="food"
                    className={
                      selected == "FOOD_MERCHANT" ? " category-bg-active" : ""
                    }
                  >
                    <div
                      className={
                        selected == "FOOD_MERCHANT"
                          ? "square-box active "
                          : "square-box"
                      }
                    >
                      <span
                        className={
                          selected == "FOOD_MERCHANT"
                            ? "heading-active px-3"
                            : "heading px-3"
                        }
                      >
                        Food
                      </span>
                      <IoFastFoodOutline
                        color={selected == "FOOD_MERCHANT" ? "#000" : "#cccc"}
                        size={32}
                      />
                    </div>
                  </label>
                </div>
                <div
                  className={`col-md-4 ${
                    selected == "SHOP_MERCHANT" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="shop"
                    value="SHOP_MERCHANT"
                    name="businessCategory"
                    className="category-business"
                    // onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    htmlFor="shop"
                    className={
                      selected == "SHOP_MERCHANT" ? "category-bg-active" : ""
                    }
                  >
                    <div
                      className={
                        selected == "SHOP_MERCHANT"
                          ? "square-box active "
                          : "square-box"
                      }
                    >
                      <span
                        className={
                          selected == "SHOP_MERCHANT"
                            ? "heading-active px-3"
                            : "heading px-3"
                        }
                      >
                        Shop
                      </span>
                      <MdShoppingBasket
                        color={selected == "SHOP_MERCHANT" ? "white" : "#cccc"}
                        size={32}
                      />
                    </div>
                  </label>
                </div>
                <div
                  className={`col-md-4 ${
                    selected == "SERVICE_MERCHANT" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    id="service"
                    value="SERVICE_MERCHANT"
                    name="businessCategory"
                    className="category-business"
                    // onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    htmlFor="service"
                    className={
                      selected == "SERVICE_MERCHANT" ? "category-bg-active" : ""
                    }
                  >
                    <div
                      className={
                        selected == "SERVICE_MERCHANT"
                          ? " square-box active "
                          : " square-box"
                      }
                    >
                      <span
                        className={
                          selected == "SERVICE_MERCHANT"
                            ? "heading-active px-2"
                            : "heading px-2"
                        }
                      >
                        Service
                      </span>
                      <MdOutlineHandshake
                        color={
                          selected == "SERVICE_MERCHANT" ? "white" : "#cccc"
                        }
                        size={32}
                      />
                    </div>
                  </label>
                </div>
              </div>
              <p className="error-txt">
                {selected == null ? (error.userType ? error.userType : "") : ""}
              </p>
              {/* <ul className="row">
                <li className="col-md-4">
                  <input
                    type="radio"
                    id="food"
                    value="food"
                    name="businessCategory"
                    className="business-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />

                  <label htmlFor="food" className="business-input-label">
                    <img
                      src={
                        selected
                          ? common.loadImg("food-active.svg")
                          : common.loadImg("food-icn.svg")
                      }
                      alt="business-icn"
                    />
                    <span>Food</span>
                    <img
                      src={common.loadImg("tick-icn.svg")}
                      alt="business-icn"
                      className="tick"
                    />
                  </label>
                </li>
                <li className="col-md-4">
                  <input
                    type="radio"
                    id="apparel"
                    value="apparel"
                    name="businessCategory"
                    className="business-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label htmlFor="apparel" className="business-input-label">
                    <img
                      src={common.loadImg("apparel-icn.svg")}
                      alt="business-icn"
                    />
                    <span>Apparel</span>
                    <img
                      src={common.loadImg("tick-icn.svg")}
                      alt="business-icn"
                      className="tick"
                    />
                  </label>
                </li>
                <li className="col-md-4">
                  <input
                    type="radio"
                    id="saloonspa"
                    value="saloonspa"
                    name="businessCategory"
                    className="business-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label htmlFor="saloonspa" className="business-input-label">
                    <img
                      src={common.loadImg("saloon-spa-icn.svg")}
                      alt="business-icn"
                    />
                    <span>Saloon & Spa</span>
                    <img
                      src={common.loadImg("tick-icn.svg")}
                      alt="business-icn"
                      className="tick"
                    />
                  </label>
                </li>
                <li className="col-md-4">
                  <input
                    type="radio"
                    id="Events"
                    value="events"
                    name="businessCategory"
                    className="business-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label htmlFor="Events" className="business-input-label">
                    <img
                      src={common.loadImg("events-icn.svg")}
                      alt="business-icn"
                    />
                    <span>Events</span>
                    <img
                      src={common.loadImg("tick-icn.svg")}
                      alt="business-icn"
                      className="tick"
                    />
                  </label>
                </li>
                <li className="col-md-4">
                  <input
                    type="radio"
                    id="footwear"
                    value="footwear"
                    name="businessCategory"
                    className="business-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label htmlFor="footwear" className="business-input-label">
                    <img
                      src={common.loadImg("foot-wear-icn.svg")}
                      alt="business-icn"
                    />
                    <span>Foot Wear</span>
                    <img
                      src={common.loadImg("tick-icn.svg")}
                      alt="business-icn"
                      className="tick"
                    />
                  </label>
                </li>
                <li className="col-md-4">
                  <input
                    type="radio"
                    id="electronics"
                    value="electronics"
                    name="businessCategory"
                    className="business-category"
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label htmlFor="electronics" className="business-input-label">
                    <img
                      src={common.loadImg("electronics-icn.svg")}
                      alt="business-icn"
                    />
                    <span>Electronics</span>
                    <img
                      src={common.loadImg("tick-icn.svg")}
                      alt="business-icn"
                      className="tick"
                    />
                  </label>
                </li>
              </ul> */}
            </div>
            <div className="card-footer">
              <button className="footer-btn" onClick={handleContinue}>
                <span>Continue</span>
                <img
                  src={common.loadImg("continue-arrow.svg")}
                  alt="continue-arrow"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
