import React, { useState } from "react";
import Step1 from "../../components/profileSetup/step1";
import Step2 from "../../components/profileSetup/step2";
import Step3 from "../../components/profileSetup/step3";
import Step4 from "../../components/profileSetup/step4";
import Step5 from "../../components/profileSetup/step5";
import Step6 from "../../components/profileSetup/step6";
import Step7 from "../../components/profileSetup/step7";
import Step8 from "../../components/profileSetup/step8";

export default function ProfileSetup() {
  const [step, setStep] = useState(1);
  const handleStepSuccess = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const handleStepBack = () => {
    setStep((prevStep) => prevStep - 1);
  };
  return (
    <div className="profile-setup-wrap">
      {step == 1 && <Step1 onSuccess={handleStepSuccess} />}
      {step == 2 && (
        <Step2 onSuccess={handleStepSuccess} back={handleStepBack} />
      )}
      {/* {step == 3 && <Step3 onSuccess={handleStepSuccess} />} */}
      {step == 3 && (
        <Step4 onSuccess={handleStepSuccess} back={handleStepBack} />
      )}
      {/* {step == 5 && <Step5 onSuccess={handleStepSuccess} />} */}
      {/* {step == 6 && <Step6 onSuccess={handleStepSuccess} />} */}
      {/* {step == 3 && <Step7 onSuccess={handleStepSuccess} />}
      {step == 4 && <Step8 onSuccess={() => setStep(4)} />} */}
    </div>
  );
}
