import React, { useEffect, useRef, useState } from "react";
import { common } from "../helpers/common";
import ListTable from "../components/listTable";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AmlHashForm from "aml-hash-form";
import { api } from "../helpers/api";
import { config } from "../helpers/config";
import ViewModal from "../components/viewModal";
import { CropIt } from "../components/cropIt";
const statusOption = [
  { value: true, label: "Active" },
  { value: false, label: "Deactive" },
];

export default function Transfers(props) {
  const childRef = useRef();
  const [loading, setloading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [itemId, setItemId] = useState(null);
  const fileRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState({
    image: "",
  });
  const [imagePath, setImagePath] = useState("");
  const [isView, setIsView] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  let imageCroper = React.useRef(null);

  const formFields = {
    name: {
      validate: ["req#Name is required"],
    },

    isActive: {
      validate: ["req#Status is required"],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // handler
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = handleSubmit();
      if (isValid) {
        setloading(true);
        if (itemId) {
          if (selectedImage) {
            const [imagePath] = await Promise.all([uploadImage()]);
            editMenuItem(imagePath);
          } else {
            editMenuItem(imagePath);
          }
        } else {
          if (!selectedImage) {
            setError((prev) => ({ ...prev, image: "Please select a image" }));
          } else {
            const imagePath = await uploadImage();

            setTimeout(() => {
              createCuisines(imagePath);
            }, 1000);
          }
        }
      }
    } catch (error) {
      common.notify("E", "Error in File upload, please try again ");
    }
  };
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    let data = {
      url: "upload",
      method: "POST",
      auth: "none",
      body: formData,
      cType: 6,
    };
    return new Promise((resolve, reject) => {
      api.call(data, (res) => {
        let response = res.data;
        if (res.status == 200) {
          let image = response?.data?.key;
          setImagePath(image);
          resolve(response?.data?.key);
          return image;
        } else {
          common.notify("E", "Error In Uploading documents ,please try again");
          reject("Error in uploading logo");
        }
      });
    });
  };
  const onAction = (data) => {
    setItemId(data.item.id);
    getItemById(data.item.id);

    if (data.type === "View") {
      setIsView(true);
    } else if (data.type === "Edit") {
      let status = statusOption.filter(
        (item) => item?.value == data?.item?.isActive
      );
      setMultiValue({
        name: data?.item?.name,

        isActive: status[0],
      });
      setImagePath(data?.item?.image);

      setModalShow(true);
    } else if (data.type === "Delete") {
      deleteMenu(data.item.id);
    }
  };

  const getItemById = (itemId) => {
    setViewLoading(true);
    let getItemQuery = `query Transfer($transferId: ID!) {
      transfer(transferId: $transferId) {
          id
          merchantType
          transferredAmount
          transferredAt
          reversedAmount
          reversedAt
          isActive
          merchant {
              id
              name
              image
          }
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: getItemQuery,
        variables: {
          transferId: itemId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data?.data?.transfer;

      setSelectedItem(data);
      setViewLoading(false);
    });
  };

  // Api
  const createCuisines = async (imagePath) => {
    setloading(true);
    // api call
    let createItemQuery = `mutation CreateFoodCategory($input: FoodCategoryInput!) {
      createFoodCategory(input: $input) {
          id
          name
          image
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: createItemQuery,
        variables: {
          input: {
            name: fields.name,
            image: imagePath,
            isActive: fields.isActive?.value,
          },
        },
      },
    };

    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.data?.createFoodCategory?.id) {
        childRef.current.parentCall();
        resetForm();
        setModalShow(false);
        common.notify("S", "Food Category Added Successfully");
      }
      setloading(false);
    });
  };

  const editMenuItem = async (image) => {
    setloading(true);
    // api call
    let editItemQuery = `mutation UpdateFoodCategory($categoryId: ID!, $input: FoodCategoryInput!) {
      updateFoodCategory(categoryId: $categoryId, input: $input) {
          id
          name
          image
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: editItemQuery,
        variables: {
          categoryId: itemId,
          input: {
            name: fields.name,
            isActive: fields.isActive?.value,
            image: image,
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;

      if (data?.data?.updateFoodCategory?.id) {
        childRef.current.parentCall();
        resetForm();
        setModalShow(false);
        common.notify("S", "Food Category updated successfully");
      } else {
        common.notify("E", "Something Went wrong");
      }

      setloading(false);
    });
  };

  const deleteMenu = async (itemId) => {
    setloading(true);
    // api call
    let deleteMenuQuery = `mutation DeleteFoodCategory($categoryId: ID!) {
      deleteFoodCategory(categoryId: $categoryId)
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: deleteMenuQuery,
        variables: {
          categoryId: itemId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;

      if (data?.data?.deleteFoodCategory == true) {
        childRef.current.parentCall();
        common.notify("S", "Deleted successfully");
      }
      setloading(false);
    });
  };

  const onselect = (menu, name) => {
    setMultiValue({ [name]: menu });
  };

  const openModal = (val) => {
    resetForm();
    setModalShow(val);
  };

  const resetForm = () => {
    setMultiValue({
      name: "",

      isActive: "",
    });
    setImagePath("");
    setSelectedImage(null);
    setItemId(null);
    setloading(false);
  };
  const handleImageClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      let cropSize = {};
      cropSize = { width: 650, height: 350 };

      imageCroper.onFileChange(event, cropSize);
      setSelectedImage(file);
    } else {
      setSelectedImage(null);
    }
  };

  const cropImageComplete = (data, type) => {
    if (data.status == "success") {
      setSelectedImage(data.image);
      fileRef.current.value = "";
    } else {
      fileRef.current.value = "";
      setSelectedImage(null);
    }
  };

  return (
    <>
      <div className="pages-wrapper">
        <div className="page-title-wrap">
          <div>
            <h5 className="page-title-txt">Transfers</h5>
          </div>
          {/* <div>
            <button className="add-btn" onClick={() => openModal(true)}>
              <img
                src={common.loadImg("plus-icn.svg")}
                alt="icn"
                width="16"
                height="16"
              />
              <span>Add Transfers</span>
            </button>
          </div> */}
        </div>
        <ListTable ref={childRef} module="transfers" onAction={onAction} />
      </div>

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {itemId ? "Update Transfer" : "Add Transfer"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <label className="form-label"> Name of the Food Category</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter the name"
                  className="form-control form-input"
                  value={fields.name}
                  onChange={handleChange}
                />
                <p className="error-txt">{errors?.name}</p>
              </div>
              <div className="col-12">
                <label className="form-label">Item Image</label>
                <div className="brand-img-container" onClick={handleImageClick}>
                  {selectedImage && (
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Brand Logo"
                      className="uploaded-logo"
                    />
                  )}
                  {imagePath && !selectedImage && (
                    <img
                      src={config.api.cloudImgPath + imagePath}
                      alt="Brand Logo"
                      className="uploaded-logo"
                    />
                  )}
                  {!selectedImage && !imagePath && (
                    <div className="click-load">Click to upload image</div>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileRef}
                  className="form-control form-input visually-hidden"
                  accept="image/*"
                  onChange={(e) => handleImage(e)}
                />
                <CropIt
                  ref={(instance) => {
                    imageCroper = instance;
                  }}
                  onComplete={(data) => cropImageComplete(data, "BannerImage")}
                  shape={"rectangle"}
                />
                <p className="error-txt">{error?.image}</p>
              </div>

              <div className="col-12">
                <label className="form-label">Status</label>
                <Select
                  value={fields.isActive}
                  name="country"
                  options={statusOption}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  onChange={(data) => onselect(data, "isActive")}
                />
                <p className="error-txt">{errors.isActive}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-foot-btn dark"
              onClick={(e) => onSubmit(e)}
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* view */}
      {isView && (
        <ViewModal
          loading={viewLoading}
          show={isView}
          item={selectedItem}
          closeModal={() => setIsView(false)}
          module={"cuisines"}
          title={"Menu Items"}
        />
      )}
    </>
  );
}
