import React, { useRef, useState } from "react";
import { common } from "../../helpers/common";
import ListTable from "../../components/listTable";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AmlHashForm from "aml-hash-form";
import { api } from "../../helpers/api";
import ViewModal from "../../components/viewModal";

const statusOption = [
  { value: true, label: "Active" },
  { value: false, label: "Deactive" },
];

export default function MenuHeadings(props) {
  const childRef = useRef();
  const [loading, setloading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedStatus, setStatus] = useState();
  const [menuId, setMenuId] = useState(null);
  const [isView, setIsView] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [viewLoading, setViewLoading] = useState(false);

  const formFields = {
    name: {
      validate: ["req#Name is required"],
    },
    isActive: {
      validate: ["req#Status is required"],
    },
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      if (menuId) {
        editMenu();
      } else {
        createMenu();
      }
    }
  };

  const onAction = (data) => {
    setMenuId(data.item.id);
    getMenuById(data.item.id);
    if (data.type === "View") {
      setIsView(true);
    } else if (data.type === "Edit") {
      let status = statusOption.filter(
        (item) => item?.value == data?.item?.isActive
      );
      setMultiValue({ name: data?.item?.name, isActive: status[0] });
      setModalShow(true);
    } else if (data.type === "Delete") {
      deleteMenu(data.item.id);
    }
  };

  // Api
  const createMenu = async () => {
    setloading(true);
    // api call
    let createMenuQuery = `mutation CreateFoodMenu($input: FoodMenuInput!) {
      createFoodMenu(input: $input) {
          id
          name
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: createMenuQuery,
        variables: {
          input: {
            name: fields.name,
            isActive: fields.isActive?.value,
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.createFoodMenu &&
        Object.keys(data?.data?.createFoodMenu).length > 0
      ) {
        childRef.current.parentCall();
        setMultiValue({ name: "" });
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const editMenu = async () => {
    setloading(true);
    // api call
    let editMenuQuery = `mutation UpdateFoodMenu($menuId: ID!, $input: FoodMenuInput!) {
      updateFoodMenu(menuId: $menuId, input: $input) {
          id
          name
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: editMenuQuery,
        variables: {
          menuId: menuId,
          input: {
            name: fields?.name,
            isActive: fields?.isActive?.value,
          },
        },
      },
    };

    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.updateFoodMenu &&
        Object.keys(data?.data?.updateFoodMenu).length > 0
      ) {
        childRef.current.parentCall();
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const deleteMenu = async (menuId) => {
    setloading(true);
    // api call
    let deleteMenuQuery = `mutation DeleteFoodMenu($menuId: ID!) {
      deleteFoodMenu(menuId: $menuId)
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: deleteMenuQuery,
        variables: {
          menuId: menuId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.data?.deleteFoodMenu) {
        childRef.current.parentCall();
      }
      setloading(false);
    });
  };

  const getMenuById = (menuId) => {
    setViewLoading(true);
    let getMenuQuery = `query FoodMenu($menuId: ID!) {
      foodMenu(menuId: $menuId) {
          id
          name
          isActive
          items {
              id
              name
              description
              image
              basePrice
              taxPercentage
              categories {
                id
                name
                image
                isActive
            }
              isActive
          }
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: getMenuQuery,
        variables: {
          menuId: menuId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data?.data?.foodMenu;
      setSelectedItem(data);
      setViewLoading(false);
    });
  };

  const onselect = (menu, name) => {
    setMultiValue({ [name]: menu });
  };

  const openModal = (val) => {
    resetForm();
    setModalShow(val);
  };

  const resetForm = () => {
    setMultiValue({
      name: "",
      isActive: "",
    });
    setMenuId(null);
    setloading(false);
  };

  return (
    <>
      <div className="pages-wrapper">
        <div className="page-title-wrap">
          <div>
            <h5 className="page-title-txt">Menu</h5>
          </div>
          <div>
            <button className="add-btn" onClick={() => openModal(true)}>
              <img
                src={common.loadImg("plus-icn.svg")}
                alt="icn"
                width="16"
                height="16"
              />
              <span>Add Menu</span>
            </button>
          </div>
        </div>
        <ListTable ref={childRef} module="foodMenus" onAction={onAction} />
      </div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {menuId ? "Update Menu" : "Add Menu"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Menu Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter the name"
                  className="form-control form-input"
                  value={fields.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <p className="error-txt">{errors?.name}</p>
              </div>
              <div className="col-12">
                <label className="form-label">Status</label>
                <Select
                  value={fields.isActive}
                  name="country"
                  options={statusOption}
                  className="react-select-container"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#3073be",
                      padding: "8px",
                      className: "select-control",
                    }),
                  }}
                  onChange={(data) => onselect(data, "isActive")}
                />
                <p className="error-txt">{errors.isActive}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-foot-btn dark"
              onClick={(e) => onSubmit(e)}
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* view */}
      {isView && (
        <ViewModal
          loading={viewLoading}
          show={isView}
          item={selectedItem}
          closeModal={() => setIsView(false)}
          module={"foodMenus"}
          title={"Menus"}
        />
      )}
    </>
  );
}
