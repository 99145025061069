import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
// includes
import { common } from "../helpers/common";

export default function FrontHeader() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar-custom-bg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              src={common.loadImg("logo.svg")}
              width="278"
              height="50"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <a href="/register" className="header-auth-btn">
                Sign Up
              </a>
              <a href="/login" className="header-auth-btn">
                Sign In
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
