import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { api } from "../../helpers/api";
import { common } from "../../helpers/common";
import { useNavigate } from "react-router-dom";

export default function OtpVerification() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const [timeOut, setTimeOut] = useState(30);
  useEffect(() => {
    let timer;

    if (timeOut > 0) {
      timer = setInterval(() => {
        setTimeOut((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeOut]);

  // Api
  const ResendOtp = async () => {
    let tempData = common.tempDataGet("tempData");
    setloading(true);
    // api call
    let regQuery = `mutation InitiateRegistration($name: String!, $email: String!, $password: String!, $userType: UserType!) {
      initiateRegistration(
        input: {name: $name, email: $email, password: $password, userType: $userType}
      )
    }`;
    let options = {
      url: "base_url",
      method: "POST",
      auth: "none",
      body: {
        query: regQuery,
        variables: {
          name: tempData.name,
          email: tempData.email,
          password: tempData.password,
          // userType: tempData.userType,
          userType: "MERCHANT",
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.errors) {
        data?.errors?.length > 0 &&
          data?.errors?.map((item) => {
            if (item?.extensions?.inputErrors?.email === "DUPLICATE") {
              setError((prev) => ({ ...prev, email: "Email already exists" }));
            }
          });
      } else {
        common.notify("S", "Code is sent to" + " " + tempData.email);
        navigate("/otpverification", { replace: true });
        setTimeOut(30);
      }
      setloading(false);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (otp.length < 6) {
      setError("Enter the Otp");
    } else {
      setloading(true);
      setError("");
      let otpQuery = `mutation CompleteRegistration($otp: String!) {
        completeRegistration(input: {otp: $otp}) {
          accessToken
          refreshToken
        }
      }`;

      let options = {
        url: "base_url",
        method: "POST",
        auth: "none",
        body: {
          query: otpQuery,
          variables: {
            otp: otp,
          },
        },
      };
      api.call(options, (res) => {
        // return
        let data = res?.data;
        if (data?.errors) {
          data?.errors?.length > 0 &&
            data?.errors?.map((item) => {
              if (item?.extensions?.inputErrors?.otp === "INVALID") {
                setError("Invalid Otp");
              }
            });
        } else {
          common.localSet(
            "authData",
            common.crypt(data?.data?.completeRegistration, true)
          );
          navigate("/thanks", { replace: true });
          common.tempDataRemove("tempData");
        }
        setloading(false);
      });
    }
  };
  return (
    <div className="auth-content-wrap signin-content-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div className="auth-form-card">
              <h3 className="title"> Verification Code</h3>
              <label className="label mb-1">Enter Verfication Code</label>
              <div className="otp-input-wrap">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <p className="error-txt">{error ? error : ""}</p>
              <p className="d-flex justify-content-end mb-0">
                {timeOut ? (
                  <p className="plain-link plain-btn">
                    Resend code in {timeOut} seconds
                  </p>
                ) : (
                  <button
                    href="/forgotpassword"
                    className="plain-link plain-btn"
                    onClick={(e) => ResendOtp(e)}
                  >
                    Resend
                  </button>
                )}
              </p>
              <button className="auth-btn w-100" onClick={(e) => onSubmit(e)}>
                {loading ? "Processing" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
