import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import { common } from "../../helpers/common";
import { api } from "../../helpers/api";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({ otp: "" });

  const formFields = {
    otp: {
      validate: ["req#code is required", "otp#Please enter a valid code"],
    },
    newPassword: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Characters",
        "max:15#Password should be maximum 15 Characters",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:newPassword#Password and confirm password do not match",
      ],
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      resetPassword();
    }
  };

  const resetPassword = () => {
    setloading(true);

    // api call
    let resetPasswordQuery = `mutation ResetPassword($otp: String!, $password: String!) {
      resetPassword(input: {
        otp: $otp
        password: $password
      })
    }`;

    let data = {
      url: "base_url",
      method: "POST",
      auth: "none",
      body: {
        query: resetPasswordQuery,
        variables: {
          otp: fields.otp,
          password: fields.confirmPassword,
        },
      },
    };
    api.call(data, (res) => {
      // return
      let data = res?.data;
      if (data?.errors) {
        data?.errors?.length > 0 &&
          data?.errors?.map((item) => {
            if (item?.extensions?.inputErrors?.otp == "INVALID") {
              setError((prev) => ({ ...prev, otp: "Invalid code" }));
            }
          });
      } else {
        common.notify("S", "Password Changed Successfully");
        navigate("/login", { replace: true });
      }
      setloading(false);
    });
  };
  return (
    <div className="auth-content-wrap signin-content-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div className="auth-form-card">
              <h3 className="title">Reset Password</h3>
              <label className="label">Code</label>
              <input
                type="text"
                name="otp"
                value={fields.otp}
                className="form-control input"
                placeholder="Enter the Code"
                onChange={handleChange}
              />
              <p className="error-txt"> {error.otp ? error.otp : errors.otp}</p>
              <label className="label">New Password</label>
              <input
                type="password"
                name="newPassword"
                value={fields.newPassword}
                className="form-control input"
                placeholder="New Password"
                onChange={handleChange}
              />
              <p className="error-txt">{errors.newPassword}</p>
              <label className="label">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                value={fields.confirmPassword}
                className="form-control input"
                placeholder="Confirm Password"
                onChange={handleChange}
              />
              <p className="error-txt">{errors.confirmPassword}</p>
              <button className="auth-btn w-100" onClick={onSubmit}>
                {loading ? "Processing" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
