import React from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Step8 from "../components/profileSetup/step8";
// includes
import { common } from "../helpers/common";

export default function InnerHeader() {
  const navigate = useNavigate();
  const LogOut = (e) => {
    e.preventDefault();
    common.localRemove("authData");
    common.localRemove("userData");
    common.localRemove("profileData");
    navigate("/login");
  };
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar-custom-bg">
        <Container fluid>
          <Navbar.Brand>
            <img
              src={common.loadImg("logo.svg")}
              width="278"
              height="50"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown
                title={<img src={common.loadImg("user-icn.svg")} alt="logo" />}
                align="end"
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item>
                  {" "}
                  <Link to="/profile" className="text-none">
                    Profile
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => LogOut(e)}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
