import React from "react";
import { common } from "../helpers/common";

export default function Analytics() {
  return (
    <div className="col-12 d-flex justify-content-center align-items-center">
      <img
        src={common.loadImg("comingsoon.png")}
        className="loader-img"
        alt="application-icn"
        height={400}
        width={400}
      />
    </div>
  );
}
