import React, { useRef, useState, useEffect } from "react";
import { common } from "../helpers/common";
import ListTable from "../components/listTable";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AmlHashForm from "aml-hash-form";
import { api } from "../helpers/api";
import ViewModal from "../components/viewModal";
import Table from "react-bootstrap/Table";
import agModuleList from "../assets/files/agModule.json";
import { config } from "../helpers/config";
import { RiDeleteBinLine } from "react-icons/ri";

import {
  GetFoodCartDocument,
  GetFoodItemsDocument,
  UpdateFoodCartDocument,
  useAddFoodItemToCartMutation,
  useCreateFoodCartMutation,
  useCreateFoodItemMutation,
  useDeleteFoodCartItemMutation,
  useDeleteFoodCartMutation,
  useGetFoodCartQuery,
  useGetFoodItemQuery,
  useGetFoodItemsQuery,
  useUpdateFoodCartItemMutation,
  useUpdateFoodCartMutation,
} from "../gql/generated";

const statusOption = [
  { value: true, label: "Active" },
  { value: false, label: "Deactive" },
];

export default function Submerchant(props) {
  const childRef = useRef();
  const [loading, setloading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedStatus, setStatus] = useState();
  const [menuId, setMenuId] = useState(null);
  const [isView, setIsView] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [viewLoading, setViewLoading] = useState(false);
  const [itemPopup, setItemPopup] = useState(false);
  const [cartId, setCartId] = useState(0);

  const [itemList, setItemList] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [items, setItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemsIncart, setItemsInCart] = useState([]);
  const [editQuantity, setEditQuantity] = useState({});
  const [search, setSearch] = useState("");
  const [menuManage, setMenuManage] = useState(false);
  const [orderManage, setOrderManage] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const formFields = {
    name: {
      validate: ["req#Name is required"],
    },
    designation: {
      validate: ["req#Designation is required"],
    },
    username: {
      validate: ["req#User Name is required"],
    },
    password: {
      validate: [menuId ? "" : "req#Password is required"],
    },
    isActive: {},
  };

  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  const open = (data) => {
    setItemPopup(true);
  };
  const close = () => {
    setItemPopup(false);
  };
  const onSave = () => {
    const isValid = handleSubmit();

    if (isValid) {
      if (menuId) {
        editMerchant(menuId);
      } else {
        createSubMerchant();
      }
    }
  };
  const onAction = (data) => {
    setMenuId(data.item.id);
    getMerchantId(data.item.id);
    console.log(data.item.id);
    setEditMode(false);
    if (data.type === "View") {
      setIsView(true);
    } else if (data.type === "Edit") {
      let status = statusOption.filter(
        (item) => item?.value == data?.item?.isActive
      );
      setMultiValue({
        name: data?.item?.name,
        designation: data?.item?.designation,
        username: data?.item?.username,
        password: data?.item?.password,
        isActive: status[0],
      });
      setMenuManage(data.item.allowMenuManagement);
      setOrderManage(data.item.allowOrderManagement);
      setModalShow(true);
      setEditMode(true);
    } else if (data.type === "Delete") {
      deleteMerchant(data.item.id);
    }
  };

  const createSubMerchant = async () => {
    setloading(true);
    // api call
    let createSubMerchantQuery = `mutation CreateFoodSubMerchant($input: CreateFoodSubMerchantInput!) {
      createFoodSubMerchant(
          input: $input
      ) {
          id
          name
          username
          designation
          allowMenuManagement
          allowOrderManagement
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: createSubMerchantQuery,
        variables: {
          input: {
            name: fields.name,
            username: fields.username,
            password: fields.password,
            designation: fields.designation,
            // isActive: fields.isActive?.value,
            allowMenuManagement: menuManage,
            allowOrderManagement: orderManage,
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.createFoodSubMerchant &&
        Object.keys(data?.data?.createFoodSubMerchant).length > 0
      ) {
        childRef.current.parentCall();
        setMultiValue({ name: "" });
        setModalShow(false);
      }
      setloading(false);
    });
  };

  const editMerchant = async (menuId) => {
    setloading(true);
    console.log("user", fields.username);
    // api call
    let editMenuQuery = `mutation UpdateFoodSubMerchant($userId: ID!, $input: UpdateFoodSubMerchantInput!) {
      updateFoodSubMerchant(userId: $userId, input: $input) {
          id
          name
          username
          designation
          allowMenuManagement
          allowOrderManagement
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: editMenuQuery,
        variables: {
          userId: menuId,
          input: {
            name: fields?.name,
            username: fields?.username,
            designation: fields?.designation,
            isActive: fields?.isActive?.value,
            allowMenuManagement: menuManage,
            allowOrderManagement: orderManage,
          },
        },
      },
    };

    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (
        data?.data?.updateFoodSubMerchant &&
        Object.keys(data?.data?.updateFoodSubMerchant).length > 0
      ) {
        childRef.current.parentCall();
        setModalShow(false);
        common.notify("S", "Merchant Updated Successfully");
      }
      setloading(false);
    });
  };

  const deleteMerchant = async (menuId) => {
    setloading(true);
    // api call
    let deleteCartQuery = `mutation DeleteFoodSubMerchant($userId: ID!) {
      deleteFoodSubMerchant(userId: $userId)
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: deleteCartQuery,
        variables: {
          userId: menuId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      if (data?.data?.deleteFoodSubMerchant) {
        childRef.current.parentCall();
      }
      setloading(false);
    });
  };

  const getMerchantId = (menuId) => {
    setViewLoading(true);
    let getMerchantQuery = `query FoodSubMerchant($userId: ID!) {
      foodSubMerchant(userId: $userId) {
          id
          name
          username
          designation
          allowMenuManagement
          allowOrderManagement
          isActive
      }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: getMerchantQuery,
        variables: {
          userId: menuId,
        },
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data?.data?.foodSubMerchant;
      setSelectedItem(data);
      setViewLoading(false);
    });
  };

  const onselect = (menu, name) => {
    setMultiValue({ [name]: menu });
  };

  const openModal = (val) => {
    resetForm();
    setModalShow(val);
    setEditMode(false);
  };

  const resetForm = () => {
    setMultiValue({
      name: "",
      isActive: "",
    });
    setMenuId(null);
    setloading(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  return (
    <>
      <div className="pages-wrapper">
        <div className="page-title-wrap">
          <div>
            <h5 className="page-title-txt">Sub Merchant</h5>
          </div>
          <div>
            <button className="add-btn" onClick={() => openModal(true)}>
              <img
                src={common.loadImg("plus-icn.svg")}
                alt="icn"
                width="16"
                height="16"
              />
              <span>Add Sub-Merchant</span>
            </button>
          </div>
        </div>
        <ListTable
          ref={childRef}
          module="foodSubMerchants"
          onAction={onAction}
        />
      </div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {menuId ? "Update Sub-Merchant" : "Add Sub-Merchant"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-3">
            <div className="row">
              <div className="col-12">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter the name"
                  className="form-control form-input"
                  value={fields.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <p className="error-txt">{errors?.name}</p>
              </div>
              <div className="col-12">
                <label className="form-label">Designation</label>
                <input
                  name="designation"
                  type="text"
                  placeholder="Enter the name"
                  className="form-control form-input"
                  value={fields.designation}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <p className="error-txt">{errors?.designation}</p>
              </div>
              <div className="col-12">
                <label className="form-label">User Name</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Enter the name"
                  className="form-control form-input"
                  value={fields.username}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <p className="error-txt">{errors?.username}</p>
              </div>
              {!editMode && (
                <div className="col-12">
                  <label className="form-label">Password</label>
                  <input
                    type="text"
                    name="password"
                    placeholder="Enter the name"
                    className="form-control form-input"
                    value={fields.password}
                    autocomplete="off"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <p className="error-txt">{errors?.password}</p>
                </div>
              )}
              {editMode && (
                <>
                  <div className="col-12">
                    <label className="form-label">Status</label>
                    <Select
                      value={fields.isActive}
                      name="isActive"
                      options={statusOption}
                      className="react-select-container"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: "#3073be",
                          padding: "8px",
                          className: "select-control",
                        }),
                      }}
                      onChange={(data) => onselect(data, "isActive")}
                    />
                    <p className="error-txt">{errors.isActive}</p>
                  </div>
                </>
              )}
              <div className="col-6">
                <div class="form-check form-switch cus-switch">
                  <input
                    name="allowMenuManagement"
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={menuManage}
                    onChange={(e) => setMenuManage(e.target.checked)}
                  />

                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    Allow Menu Management
                  </label>
                </div>
              </div>

              <div className="col-6 ">
                <div class="form-check form-switch cus-switch">
                  <input
                    name="allowOrderManagement"
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault2"
                    checked={orderManage}
                    onChange={(e) => setOrderManage(e.target.checked)}
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    Allow Order Management
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal-btn-container">
            <button
              className="modal-foot-btn light"
              onClick={() => setModalShow(false)}
            >
              Cancel
            </button>
            <button
              className="modal-foot-btn dark"
              // onClick={(e) => onSubmit(e)}
              onClick={(e) => onSave(e)}
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* view */}
      {isView && (
        <ViewModal
          loading={viewLoading}
          show={isView}
          item={selectedItem}
          closeModal={() => setIsView(false)}
          module={"foodSubMerchants"}
          title={"SubMerchants"}
        />
      )}
    </>
  );
}
