import React, { useEffect, useState } from "react";
import { api } from "../../helpers/api";
import { common } from "../../helpers/common";
import { useNavigate } from "react-router-dom";

export default function Thanks() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    // api call
    // let userQuery = `query GetCurrentUser {
    //   currentUser {
    //     __typename
    //     id
    //     name
    //     email
    //     avatar
    //     socialAvatar
    //     userType
    //     ... on FoodMerchantUser {
    //       profile {
    //         __typename
    //         id
    //          name
    //         description
    //         orderTypes
    //         country
    //         addressLine1
    //         addressLine2
    //         city
    //         state
    //         postalCode
    //         location {
    //           lat
    //           lng
    //         }
    //         businessRegistrationNumber
    //         businessDocuments
    //         vatId
    //         orderTypes
    //         operatingDays {
    //           id
    //           weekDay
    //           weekDayName
    //           isOpen
    //           openingTime
    //           closingTime
    //         }
    //         stripeAccountStatus
    //       }
    //     }
    //   }
    // }`;
    let userQuery = `query CurrentUser {
      currentUser {
          id
          name
          email
          avatar
          socialAvatar
          userType
          profile {
              __typename
              ... on FoodMerchantPrivate {
                  id
                  name
                  description
                  image
                  bannerImage
                  phone
                  email
                  country
                  addressLine1
                  addressLine2
                  city
                  state
                  postalCode
                  businessRegistrationNumber
                  businessDocuments
                  vatId
                  orderTypes
                  websiteUrl
                  stripeAccountStatus
                  isActive
                  location {
                      lat
                      lng
                  }
                  operatingDays {
                      id
                      weekDay
                      weekDayName
                      isOpen
                      openingTime
                      closingTime
                  }
              }
          }
      }
    }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: userQuery,
      },
    };
    api.call(options, (res) => {
      // return
      let data = res?.data;
      common.localSet("userData", common.crypt(data?.data?.currentUser, true));
      setUser(data?.data?.currentUser);
    });
  };
  const handleContinue = () => {
    if (user?.profile) {
      if (user?.profile?.stripeAccountStatus == "complete") {
        navigate("/dashboard", { replace: true });
      } else {
        createStripe();
      }
    } else {
      navigate("/profilesetup", { replace: true });
    }
  };

  const createStripe = () => {
    // api call
    let stripeQuery = `query GetStripeAccountLink($input: StripeAccountLinkInput!) {
    stripeAccountLink(input: $input) {
      url
      created
      expiresAt
    }
  }`;

    let options = {
      url: "base_url",
      method: "POST",
      auth: "token",
      body: {
        query: stripeQuery,
        variables: {
          input: {
            type: "account_onboarding",
            collect: "eventually_due",
            refreshUrl: window.location.origin,
            returnUrl: window.location.origin + "/dashboard",
          },
        },
      },
    };
    api.call(options, (res) => {
      // return
      let response = res?.data;
      if (response?.data?.stripeAccountLink) {
        window.location.replace(response?.data?.stripeAccountLink?.url);
      }
    });
  };
  return (
    <div className="auth-content-wrap thanks-content-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div className="auth-form-card thanks-card">
              <p className="thanks-msg-title">
                Hi, <span>{user?.name} </span> Merchant, <br />
                Thank you for registering with us!!
              </p>
              <p className="thanks-msg-desc">
                We need some additional information about you. Please click the
                button to set-up your profile.
              </p>
              <button className="auth-btn w-100" onClick={handleContinue}>
                Let's Go
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
