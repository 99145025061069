import React from "react";
import { common } from "../helpers/common";

export default function Poc() {
  return (
    <div className="pages-wrapper">
      <div className="page-title-wrap">
        <div>
          <h5 className="page-title-txt">Details Page</h5>
        </div>
      </div>
      <div className="list-table-wrapper p-0">
        <div className="container">
          <ul className="row detail-list-data">
            <li className="col-md-4">
              <label className="detail-list-label">S.No</label>
              <p className="details-list-data">#BMH01</p>
            </li>
            <li className="col-md-4">
              <label className="detail-list-label">Categories Name</label>
              <p className="details-list-data">Tandoori Specialities</p>
            </li>
            <li className="col-md-4">
              <label className="detail-list-label">Added</label>
              <p className="details-list-data">27-Sep-2023</p>
            </li>
            <li className="col-md-4">
              <label className="detail-list-label">Status</label>
              <p className="details-list-data">Active</p>
            </li>
            <li className="col-md-4">
              <label className="detail-list-label mb-2">Image</label>
              <img src={common.loadImg("branch-banner.png")} alt="" className="w-100" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
