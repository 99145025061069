import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { config } from "../helpers/config";
import { common } from "../helpers/common";
import moment from "moment";
import StatusBar from "./statusBar";
import QRCode from "react-qr-code";
export default function ViewModal(props) {
  let pattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,6})?Z/;
  return (
    <Modal
      show={props.show}
      onHide={() => props.closeModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          View {props?.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <>
          {props.loading ? (
            <StatusBar width={100} height={100} />
          ) : (
            <>
              <div className="container mb-3">
                <div className="row">
                  {Object.keys(props?.item).length > 0 &&
                    Object.keys(props?.item).map((key) => {
                      if (
                        key !== "id" &&
                        key !== "items" &&
                        key !== "payments" &&
                        key !== "order"
                      ) {
                        return (
                          <div className="col-6">
                            <label className="form-label view-label">
                              {common.camelCaseSplit(key)}
                            </label>
                            {/* !props?.item?.items &&  */}

                            <p>
                              {key == "image" || key == "bannerImage" ? (
                                <img
                                  src={
                                    config.api.cloudImgPath + props?.item[key]
                                  }
                                  alt="Item image"
                                  className="view-img"
                                />
                              ) : key == "cuisine" ? (
                                <b>{props?.item[key].name}</b>
                              ) : key == "menus" ? (
                                <b>
                                  {props?.item[key].length > 0 &&
                                    props?.item[key].map((k, i) => {
                                      return (
                                        <span key={i}>
                                          {k.name}
                                          {props?.item[key].length > 1 &&
                                            props?.item[key].length - 1 !== i &&
                                            ", "}
                                        </span>
                                      );
                                    })}
                                </b>
                              ) : typeof props?.item[key] === "boolean" ? (
                                <b>
                                  {props?.item[key] ? "Active" : "Deactive"}
                                </b>
                              ) : typeof props?.item[key] === "string" ? (
                                <b>
                                  {" "}
                                  {pattern.test(props?.item[key])
                                    ? moment(props?.item[key]).format(
                                        "YYYY-MM-DD"
                                      ) +
                                      "    " +
                                      moment(props?.item[key]).format("HH:mm A")
                                    : props?.item[key]}{" "}
                                </b>
                              ) : key === "businessDocuments" ? (
                                <b>
                                  {" "}
                                  {props?.item[key].length > 0 &&
                                    props?.item[key].map((val, j) => {
                                      return (
                                        <span key={j}>
                                          {/* {common.fileName(val)} */}
                                          {val}
                                          <br />
                                        </span>
                                      );
                                    })}{" "}
                                </b>
                              ) : key === "orderTypes" ? (
                                <b>
                                  {props?.item[key].length > 0 &&
                                    props?.item[key].map((val, j) => {
                                      return (
                                        <span key={j}>
                                          {val}

                                          <br />
                                        </span>
                                      );
                                    })}{" "}
                                </b>
                              ) : key === "user" ? (
                                <b>{props?.item[key]?.name}</b>
                              ) : key === "merchant" ? (
                                <>
                                  <b>{props?.item[key]?.name}</b>
                                  <br></br>
                                  <img
                                    src={
                                      config.api.cloudImgPath +
                                      props?.item[key]?.image
                                    }
                                    alt="Item image"
                                    className="view-img"
                                  />
                                </>
                              ) : (
                                "-"
                              )}
                            </p>
                          </div>
                        );
                      }
                    })}{" "}
                  {props.module == "foodCart" &&
                    props?.item?.items?.length > 0 && (
                      <div className="col-6">
                        <label className="form-label view-label">QR Code</label>
                        <div>
                          <QRCode
                            value={JSON.stringify({
                              id: props?.item?.id,
                              type: "FoodCart",
                            })}
                            size={200}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {props.module == "foodCart" && props?.item?.items?.length > 0 && (
                <div className="list-table-wrapper">
                  <h5>Cart Items</h5>
                  <div className="table-wrapper">
                    <Table responsive striped>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Description</th>
                          <th>Price</th>
                          <th>tax</th>

                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props?.item?.items?.map((data, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{data?.item?.name}</td>
                            <td>
                              <img
                                src={
                                  config.api.cloudImgPath + data?.item?.image
                                }
                                alt="Item image"
                                className="view-img"
                              />
                            </td>
                            <td>{data?.item?.description}</td>
                            <td>{data?.item?.basePrice}</td>
                            <td>{data?.item?.taxPercentage}</td>
                            {/* <td>{data?.isActive ? "Active" : "Deactive"}</td> */}
                            <td>{data?.quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
              {props.module == "foodMenus" &&
                props?.item?.items?.length > 0 && (
                  <div className="list-table-wrapper">
                    <h5>Menu Items</h5>
                    <div className="table-wrapper">
                      <Table responsive striped>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>tax</th>
                            <th>cuisine</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props?.item?.items?.map((data, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data?.name}</td>
                              <td>
                                <img
                                  src={config.api.cloudImgPath + data?.image}
                                  alt="Item image"
                                  className="view-img"
                                />
                              </td>
                              <td>{data?.description}</td>
                              <td>{data?.basePrice}</td>
                              <td>{data?.taxPercentage}</td>
                              <td>{data?.cuisine?.name}</td>
                              <td>{data?.isActive ? "Active" : "Deactive"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}

              {(props.module == "foodOrders" || props.module == "order") && (
                <>
                  {props?.item?.items?.length > 0 && (
                    <div className="list-table-wrapper">
                      <h5>Order Items</h5>
                      <div className="table-wrapper">
                        <Table responsive striped>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Item Name</th>
                              <th>Image</th>
                              <th>Description</th>
                              <th>Price</th>
                              <th>tax</th>
                              <th>Quantity</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props?.item?.items?.map((data, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{data?.item?.name}</td>
                                <td>
                                  <img
                                    src={
                                      config.api.cloudImgPath +
                                      data?.item?.image
                                    }
                                    alt="Item image"
                                    className="uploaded-logo"
                                  />
                                </td>
                                <td>{data?.item?.description}</td>
                                <td>{data?.item?.basePrice}</td>
                                <td>{data?.item?.taxPercentage}</td>
                                <td>{data?.quantity}</td>
                                <td>
                                  {data?.item?.isActive ? "Active" : "Deactive"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                  {props?.item?.payments?.length > 0 && (
                    <div className="list-table-wrapper">
                      <h5>Order Payments</h5>
                      <div className="table-wrapper">
                        <Table responsive striped>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Payment Id</th>
                              <th>Order Id</th>
                              <th>Transaction Amount</th>
                              <th>Paid At</th>
                              <th>payment Method Type</th>
                              <th>Stripe Payment Id</th>
                              <th>Wallet Payment Id</th>
                              <th>Payment Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props?.item?.payments?.map((data, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{data?.id}</td>
                                <td>{data?.order?.id}</td>
                                <td>{data?.amount}</td>
                                <td>{data?.paidAt}</td>
                                <td>
                                  {data?.paymentMethodType
                                    ? data?.paymentMethodType
                                    : " - "}
                                </td>
                                <td>
                                  {data?.stripePaymentId
                                    ? data?.stripePaymentId
                                    : " - "}
                                </td>
                                <td>
                                  {data?.walletPaymentId
                                    ? data?.walletPaymentId
                                    : " - "}
                                </td>
                                <td> {data?.paymentStatus} </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                </>
              )}

              {props.module == "payments" &&
                props?.item?.order?.items.length > 0 && (
                  <>
                    <div className="list-table-wrapper">
                      <h5>Order Details</h5>
                      <div className="table-wrapper">
                        <Table responsive striped>
                          <thead>
                            <tr>
                              <th>Order Id</th>
                              <th>Order Type</th>
                              <th>Sub Total</th>
                              <th>Tax Total</th>
                              <th>Grand Total</th>
                              <th>Order Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>{props?.item?.order?.id}</td>
                              <td>{props?.item?.order?.type}</td>
                              <td>{props?.item?.order?.subTotal}</td>
                              <td>{props?.item?.order?.taxTotal}</td>
                              <td>{props?.item?.order?.grandTotal}</td>
                              <td>
                                {moment(props?.item?.order?.orderedAt).format(
                                  "ll HH:mm A"
                                )}
                              </td>
                              <td>{props?.item?.order?.status}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="list-table-wrapper">
                      <h5>Order Items</h5>
                      <div className="table-wrapper">
                        <Table responsive striped>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Id</th>
                              <th>Item Name</th>
                              <th>Image</th>
                              <th>Description</th>
                              <th>Price</th>
                              <th>tax</th>
                              <th>Quantity</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {props?.item?.order?.items?.map((data, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{data?.item?.id}</td>
                                <td>{data?.item?.name}</td>
                                <td>
                                  <img
                                    src={
                                      config.api.cloudImgPath +
                                      data?.item?.image
                                    }
                                    alt="Item image"
                                    className="menu-img"
                                  />
                                </td>
                                <td>{data?.item?.description}</td>
                                <td>{data?.item?.basePrice}</td>
                                <td>{data?.item?.taxPercentage}</td>
                                <td>{data?.quantity}</td>
                                <td>
                                  {data?.item?.isActive ? "Active" : "Deactive"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-btn-container">
          <button
            className="modal-foot-btn light"
            onClick={() => props.closeModal()}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
