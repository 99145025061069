import {
	ApolloClient,
	HttpLink,
	from,
	fromPromise,
	split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import cache from './cache.ts';
import { jwtDecode } from 'jwt-decode';
import { RefreshTokensMutation } from './generated.ts';
import { config } from '../helpers/config';
import { common } from '../helpers/common';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
// import {
// 	clearAuthData,
// 	getGlobalState,
// 	setAuthData,
// 	setGlobalState,
// } from '../helpers/global-state';

// const infoLink = setContext(async (_, { headers }) => {
// 	const location = getGlobalState('location');

// 	return {
// 		headers: {
// 			...headers,
// 			...(import.meta.env.DEV && { ['x-real-ip']: '175.157.47.182' }),
// 			...(location && {
// 				['x-client-location']: `${location.lat}:${location.lng}`,
// 			}),
// 		},
// 	};
// });

const authLink = setContext(async (_, { headers }) => {
	// get the authentication token from local storage if it exists
	let authData = common.getAuth();

	let token = (authData?.accessToken as string) || undefined;

	if (token) {
		token = await checkAndGetAccessToken(token, 5);
	}

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			'x-authorization': token ? `Bearer ${token}` : '',
		},
	};
});

let accessTokenRequest: null | Promise<string>;

function resetAccessTokenRequest() {
	accessTokenRequest = null;
}

const refreshQuery = `
mutation RefreshTokens($refreshToken: String!) {
	refreshTokens(input: { refreshToken: $refreshToken }) {
		accessToken
		refreshToken
	}
}`;

async function getNewAccessToken(): Promise<string> {
	let accessToken = '';

	let authData = common.getAuth();

	let refreshToken = (authData?.refreshToken as string) || undefined;

	if (refreshToken) {
		const response = await fetch(`${config.api.url}/graphql`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json;charset=UTF-8',
			},
			body: JSON.stringify({
				query: refreshQuery,
				variables: {
					refreshToken: refreshToken,
				},
			}),
		});

		if (response.ok) {
			const result = (await response.json()) as {
				data?: RefreshTokensMutation;
				errors?: {
					message: string;
					path: string[];
					extensions?: { [key: string]: any };
				}[];
			};

			if (result.data && result.data.refreshTokens) {
				common.localSet(
					'authData',
					common.crypt(result.data.refreshTokens, true)
				);
				accessToken = result.data.refreshTokens.accessToken;
			}

			if (
				result.errors &&
				result.errors.some((err) => err.extensions?.code === 'TOKEN_EXPIRED')
			) {
				localStorage.clear();
				common.redirect('login');
			}
		} else {
			common.redirect('login');
		}
	}

	return accessToken;
}

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			if (
				graphQLErrors.some((err) => err.extensions?.code === 'TOKEN_EXPIRED')
			) {
				if (!accessTokenRequest) {
					accessTokenRequest = getNewAccessToken();
					accessTokenRequest.then(resetAccessTokenRequest);
				}

				return fromPromise(accessTokenRequest).flatMap((accessToken) => {
					if (typeof accessToken === 'string') {
						operation.setContext(({ headers = {} }) => ({
							headers: {
								...headers,
								authorization: `Bearer ${accessToken}`,
							},
						}));
					}
					return forward(operation);
				});
			}
		}

		if (networkError) console.log(`[Network error]: ${networkError}`);
	}
);

const httpLink = new HttpLink({
	uri: `${config.api.url}/graphql`,
});

const wsLink = new GraphQLWsLink(
	createClient({
		url: `${config.api.subscription_url}/graphql`,
		connectionParams: async () => {
			// get the authentication token from local storage if it exists
			let authData = common.getAuth();

			let token = (authData?.accessToken as string) || undefined;

			if (token) {
				token = await checkAndGetAccessToken(token, 5);
			}

			return {
				authorization: token ? token : '',
			};
		},
	})
);

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httpLink
);

export const apolloClient = new ApolloClient({
	cache: cache,
	link: from([authLink, errorLink, splitLink]),
	connectToDevTools: true,
});

export function clearAuthDataAndCache() {
	localStorage.clear();
	apolloClient.clearStore();
}

async function checkAndGetAccessToken(token: string, offset: number) {
	const { exp } = jwtDecode<{ exp: number }>(token);

	const now = Math.floor(Date.now() / 1000);

	if (now < exp - offset) {
		return token;
	}

	if (!accessTokenRequest) {
		accessTokenRequest = getNewAccessToken();
		accessTokenRequest.then(resetAccessTokenRequest);
	}

	return accessTokenRequest;
}
