import React from "react";
import { useState } from "react";
import AmlHashForm from "aml-hash-form";
import { useNavigate } from "react-router-dom";
import { api } from "../../helpers/api";
import { common } from "../../helpers/common";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, setError] = useState({ email: "" });

  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  const onSubmit = (e) => {
    e.preventDefault();
    let isValid = handleSubmit(e);
    if (isValid) {
      forgotPassword();
    }
  };

  // Api
  const forgotPassword = async () => {
    setloading(true);
    // api call
    let forgotPasswordQuery = `mutation ForgotPassword($email: String!) {
      forgotPassword(input: {
        email: $email
      })
    }`;

    let data = {
      url: "base_url",
      method: "POST",
      auth: "none",
      body: {
        query: forgotPasswordQuery,
        variables: {
          email: fields.email,
        },
      },
    };
    api.call(data, (res) => {
      // return
      let data = res?.data;
      if (data?.errors) {
        data?.errors?.length > 0 &&
          data?.errors?.map((item) => {
            if (
              item?.extensions?.inputErrors?.email === "NOT_FOUND" ||
              item?.extensions?.code === "UNEXPECTED_ERROR"
            ) {
              setError((prev) => ({ ...prev, email: "Invalid Email" }));
            }
          });
      } else {
        common.notify("S", "Code has been sent to" + " " + fields.email);
        navigate("/resetPassword", { replace: true });
      }
    });

    setloading(false);
  };
  return (
    <div className="auth-content-wrap signin-content-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mx-auto">
            <div className="auth-form-card">
              <h3 className="title">Forgot Password</h3>
              <label className="label">Email</label>
              <input
                type="email"
                name="email"
                value={fields.email}
                className="form-control input"
                placeholder="Enter the email"
                onChange={handleChange}
              />
              <p className="error-txt">
                {" "}
                {error.email ? error.email : errors.email}
              </p>
              <button className="auth-btn w-100" onClick={(e) => onSubmit(e)}>
                {loading ? "Processing" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
