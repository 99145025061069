import { AES, enc } from 'crypto-js';
import { enqueueSnackbar } from 'notistack';
import { api } from './api';
// includes
import { config } from './config';

export const common = {
	Capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	},

	camelCaseSplit(str) {
		return str.replace(/([a-z](?=[A-Z]))/g, '$1 ');
	},

	loadImg(img) {
		let result = require('../assets/img/' + img);
		return result;
	},

	// local storage
	localSet(key, value) {
		if (typeof value === 'object') {
			localStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.setItem(key, value);
		}
	},
	localGet(key, json = false) {
		let value = localStorage.getItem(key);
		if (json) {
			value = JSON.parse(value);
		}
		return value;
	},
	localRemove(key) {
		localStorage.removeItem(key);
	},

	// encode decode
	crypt(str, json = false) {
		if (json) {
			str = JSON.stringify(str);
		}
		return AES.encrypt(str, config.cryptKey).toString();
	},

	deCrypt(str, json = false) {
		try {
			let bytes = AES.decrypt(str, config.cryptKey);
			let data = bytes.toString(enc.Utf8);
			if (data == '') {
				localStorage.clear();
				this.redirect('login');
			} else {
				if (json) {
					return JSON.parse(data);
				} else {
					return data;
				}
			}
		} catch (err) {
			localStorage.clear();
			this.redirect('login');
		}
	},

	// auth
	checkAuth() {
		let authData = this.localGet('authData');
		if (authData == '' || authData == null) {
			return false;
		} else {
			return this.getAuth() ? true : false;
		}
	},

	getAuth() {
		let data = this.localGet('authData');
		return this.deCrypt(data, true);
	},

	redirect(url = '') {
		window.location.href = config.public_url + url;
	},

	// tempdata
	tempDataSet(value) {
		this.localSet('tempData', common.crypt(value, true));
	},

	tempDataGet() {
		let data = this.localGet('tempData');
		if (data) {
			return this.deCrypt(data, true);
		}
		return null;
	},

	tempDataRemove() {
		return this.localRemove('tempData');
	},

	// notify(type, msg) {
	//   toast.dismiss();
	//   switch (type) {
	//     case "S":
	//       toast.success(msg, config.elements.toast);
	//       break;
	//     case "W":
	//       toast.warning(msg, config.elements.toast);
	//       break;
	//     case "E":
	//     case "E":
	//       toast.error(msg, config.elements.toast);
	//       break;
	//     case "I":
	//       toast.info(msg, config.elements.toast);
	//       break;
	//     case "M":
	//       toast(msg, config.elements.toast);
	//       break;
	//   }
	// },

	/**Upload Files */
	uploadDocument: async (files) => {
		let uploadInfo = [];

		const promises = Array.from(files).map((file) => {
			const formData = new FormData();
			formData.append('file', file);

			let data = {
				url: 'upload',
				method: 'POST',
				auth: 'token',
				body: formData,
				cType: 6,
			};

			return new Promise((resolve, reject) => {
				api.call(data, (res) => {
					if (res.status === 200) {
						const body = res.data;
						uploadInfo.push(body.data.key);

						resolve(res);
					} else {
						reject(new Error('File upload failed'));
					}
				});
			});
		});

		try {
			await Promise.all(promises);

			return uploadInfo;
		} catch (error) {
			throw error;
		}
	},

	notify(type, msg = '', action) {
		switch (type) {
			case 'S':
				enqueueSnackbar(msg, { variant: 'success', autoHideDuration: 2000, action });
				break;
			case 'E':
				enqueueSnackbar(msg, { variant: 'error', autoHideDuration: 2000 });
				break;
			case 'W':
				enqueueSnackbar(msg, { variant: 'warning', autoHideDuration: 2000 });
				break;
			case 'I':
				enqueueSnackbar(msg, { variant: 'info', autoHideDuration: 2000 });
				break;
			case 'T':
				enqueueSnackbar(msg, { variant: 'toast', autoHideDuration: 2000 });
				break;
			case 'C':
				enqueueSnackbar.closeAll();
				break;
		}
	},
	fileName(file) {
		if (file && file.length) {
			let ext = file.substring(file.lastIndexOf('.'));
			let fileName = file.split('_').slice(0, -1).join('_') + ext;
			return fileName.substring(fileName.indexOf('/') + 1);
		}
		return '';
	},
};
